<template>
  <div
    :class="[
      'task',
      {
        deleted: localTask.isDeleted,
        completed: localTask.isDone,
      },
    ]"
  >
    <header class="task-header">
      <h5 class="task-header__title">{{ localTask.title }}</h5>
      <task-status-button
        :isDone="localTask.isDone"
        @status-updated="updateTaskStatus"
        class="no-drag"
      />
      <dropdown-component
        class="dropdown no-drag"
        :options="dropdownOptions"
        @select-option="onDropdownSelect"
      />
    </header>
    <section class="task-body">
      <p class="task-body__description">{{ localTask.description }}</p>
      <div class="task-body__info">
        <time :datetime="localTask.date">{{ localTask.date }}</time>
      </div>
      <ul class="task-body__list">
        <li
          v-for="assignee in localTask.assignees"
          :key="assignee.id"
          class="assignee"
          :title="`${assignee.contact.name} (${assignee.email})`"
        >
          <span>
            {{ $t("tasks.item.assignee") }}
          </span>
          : {{ assignee.contact.name }} ({{ assignee.email }})
        </li>
        <li
          v-for="watcher in localTask.watchers"
          :key="watcher.id"
          class="watcher"
          :title="`${watcher.contact.name} (${watcher.email})`"
        >
          <span>
            {{ $t("tasks.item.watcher") }}
          </span>
          : {{ watcher.contact.name }} ({{ watcher.email }})
        </li>
      </ul>
    </section>
    <footer class="task-footer" v-if="localTask.priority">
      <span
        :data-priority="localTask.priority.value"
        class="task-footer__priority"
      >
        {{ $t("tasks.modal.addTask.priorities." + localTask.priority.label) }}
      </span>
    </footer>
  </div>
</template>

<script>
import DropdownComponent from "./DropdownComponent.vue";
import TaskStatusButton from "./TaskStatusButton.vue";

export default {
  components: { TaskStatusButton, DropdownComponent },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localTask: this.task,
    };
  },
  computed: {
    dropdownOptions() {
      let list = ["view"];
      if (this.localTask.isDone) list.push("view");

      const userId = Number(this.$userStore.userId);
      const isCreator = userId === Number(this.localTask.creatorId);
      if (isCreator) list.push("edit", "delete");
      return list;
    },
  },
  watch: {
    task: {
      handler(newTask) {
        this.localTask = newTask;
      },
      deep: true,
    },
  },
  methods: {
    updateTaskStatus(newStatus) {
      this.localTask.status = newStatus;
      this.$emit("status-changed", this.localTask);
    },
    onDropdownSelect(option) {
      this.$emit("dropdown-select", option, this.localTask);
    },
  },
};
</script>

<style scoped lang="scss" src="./TaskComponent.scss" />
