<template>
  <div
    v-if="Number($userStore.roleId) === 4"
    class="db-personal-settings-view"
  >
    <div class="db-personal-settings-view__tabs">
      <div
        class="db-personal-settings-view__tab"
        :class="{ 'db-personal-settings-view__tab-active': activeTab === code }"
        v-for="{ code, title } in tabs"
        :key="code"
        @click="selectTab(code)"
      >
        {{ $t(title) }}
      </div>
    </div>

    <div class="db-personal-settings-view-form">
      <component
        :is="activeTab"
      />
    </div>
  </div>
</template>

<script>
import Addition from './components/addition/AdditionMain.vue';

const TABS = [
  {
    title: 'settings.addition',
    code: 'addition'
  }
]

export default {
  components: {
    Addition,
  },
  data() {
    return {
      activeTab: 'addition',
      tabs: TABS
    }
  },
  mounted() {
    if(Number(this.$userStore.roleId) !== 4) {
      this.$router.push('/')
    }
  },
  methods: {
    selectTab(tab) {
      this.activeTab = tab;
    }
  }
}
</script>

<style src="./style.scss" lang="scss"/>
