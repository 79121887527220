<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    this.updateDataUser()
    this.redirectToMobileInDevPage()

    window.addEventListener('resize', this.redirectToMobileInDevPage)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.redirectToMobileInDevPage)
  },
  methods: {
    async updateDataUser () {
      if (!this.$userStore.userId) {
        return
      }

      const { data } = await this.$api.get(`/v1/user/get/${this.$userStore.userId}`)

      this.seedUserData(data)
    },
    seedUserData (data) {
      this.$userStore.email = data.email
      this.$userStore.currency = data.currency
      this.$userStore.name = data.contact.name
      this.$userStore.roleId = data.role.id
    },
    redirectToMobileInDevPage () {
      if (this.$route.name === 'mobile-in-dev' || window.innerWidth > 650) {
        return
      }

      this.$router.push("/mobile-page");
    }
  }
}
</script>

<style src="./style.scss" lang="scss" />
