<template>
    <div class="auth-view">
        <img src="@/assets/sakora_logo.svg" class="auth-view__logo">
        <div class="auth-view__container">
            <span class="auth-view__heading">{{ $t('auth.authorization') }}</span>

            <form class="auth-view__form" @submit.prevent="auth">
                <input
                  type="email"
                  class="auth-view__input"
                  :placeholder="$t('auth.email')"
                  v-model="authData.email"
                  required
                />
                <input
                  type="password"
                  class="auth-view__input"
                  :placeholder="$t('auth.password')"
                  v-model="authData.password"
                  required
                />
                <button
                  type="submit"
                  class="auth-view__button"
                >{{ $t('auth.login') }}</button>
            </form>

            <div
              v-if="false"
              class="auth-view__links"
            >
                <router-link :to="{ name: 'password-recovery' }">{{ $t('auth.recover_password') }}</router-link>
                <router-link :to="{ name: 'register' }">{{ $t('auth.registration') }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
// import { START_TARIFF_ID } from "@/constants"

export default {
    data() {
        return {
            authData: {
                email: null,
                password: null
            }
        }
    },
  methods: {
        auth() {
            this.$api.post('/v1/auth/auth', this.authData)
            .then((res) => {
                const {data} = res
                this.$userStore.email = data.email
                this.$userStore.token = data.token
                this.$userStore.roleId = data.roleId
                this.$userStore.userId = data.userId
                this.$userStore.currency = data.currency

                this.$updateAxiosInstance()

                // if (data?.tariffId === START_TARIFF_ID) {
                //   localStorage.setItem("showToast", "auth.statuses.given_ten_free_events");
                // }

                if (data.roleId === 4) {
                  this.$router.push('/dashboard/settings')

                  return
                }

                this.$router.push('/dashboard')
            })
            .catch(e => {
                this.$catch(e, this.$t('auth.errors.invalid_login_or_password'))
            })
        }
    },
    beforeMount() {
        if (this.$userStore.token && this.$userStore.token !== 'undefined' && this.$userStore.token.length) {
            this.$router.push('/dashboard')
        }
    }
}
</script>