<template>
  <div class="dashboard-layout">
    <div class="dashboard-layout__header">
      <div class="dashboard-layout__title">
        {{ $t($route.meta.title) }}
      </div>

      <!-- Показатели -->
      <div class="dashboard-layout__statistics"></div>

      <!-- Пользователь -->
      <div class="dashboard-layout__user">
        <div class="dashboard-layout__user-name">
          {{ $userStore.email }}
        </div>
        <div class="dashboard-layout__user-language">
          <span @click="toggleLocale('ru')">ru</span> |
          <span @click="toggleLocale('en')">en</span>
        </div>
        <div class="dashboard-layout__user-theme">
          <theme-toggler />
        </div>
        <div class="dashboard-layout__user-icons">
          <div class="dashboard-layout__notifications-icon" />
          <div class="dashboard-layout__menu-icon" @click.prevent="toggleMenu">
            <div class="dashboard-layout__menu-container" v-if="menu.isVisible">
              <router-link
                v-if="$checkPermissions([1, 2, 3])"
                :to="{ name: 'personal-account' }"
              >{{
                $t("menu.personal_account")
              }}</router-link>
              <router-link
                v-if="$checkPermissions([1, 2, 3])"
                :to="{ name: 'dashboard-main' }"
              >{{
                $t("menu.events")
              }}</router-link>
              <router-link
                :to="{ name: 'statistics' }"
                v-if="$checkPermissions([1])"
                >{{ $t("menu.statistics") }}</router-link
              >
              <router-link
                :to="{ name: 'tasks' }"
                v-if="$checkPermissions([1, 2, 3])"
              >
                {{ $t("menu.task_manager") }}
              </router-link>
              <router-link
                :to="{ name: 'users' }"
                v-if="$checkPermissions([1])"
                >{{ $t("menu.users") }}</router-link>
              <router-link
                :to="{ name: 'credit-types' }"
                v-if="$checkPermissions([1])"
                >{{ $t("menu.types_of_expenses") }}</router-link>
              <router-link
                :to="{ name: 'settings' }"
                v-if="$checkPermissions([4])"
              >{{ $t("menu.settings") }}</router-link>
              <router-link
                :to="{ name: 'admin-statistics' }"
                v-if="$checkPermissions([4])"
              >{{ $t("menu.admin_statistics") }}</router-link>
              <a
                v-if="$checkPermissions([1, 2, 3])"
                @click.prevent="goToTg"
                href="https://t.me/sakora"
                class="tg-link"
              >{{ $t("menu.news") }}</a>
              <a href="#" @click.prevent="logout">{{ $t("menu.exit") }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-layout__stats" v-if="isVisibleDashboardStats">
      <div class="dashboard-layout__stats-group">
        <div class="dashboard-layout__stats-group-name">
          {{ $t("dashboard.statistics.completed") }}
        </div>
        <div class="dashboard-layout__stats-group-content">
          <div class="dashboard-layout__stats-group-column">
            <span class="dashboard-layout__stats-group-column-name">{{
              $t("dashboard.statistics.enrollments")
            }}</span>
            <span class="dashboard-layout__stats-group-column-value">{{
              $convertToCurrency(statistics.completed.debit)
            }}</span>
          </div>
          <div class="dashboard-layout__stats-group-column">
            <span class="dashboard-layout__stats-group-column-name">{{
              $t("dashboard.statistics.cost")
            }}</span>
            <span class="dashboard-layout__stats-group-column-value">{{
              $convertToCurrency(statistics.completed.credit)
            }}</span>
          </div>
          <div class="dashboard-layout__stats-group-column">
            <span class="dashboard-layout__stats-group-column-name">{{
              $t("dashboard.statistics.remainder")
            }}</span>
            <span class="dashboard-layout__stats-group-column-value">{{
              $convertToCurrency(statistics.completed.balance)
            }}</span>
          </div>
        </div>
      </div>

      <div class="dashboard-layout__stats-group">
        <div class="dashboard-layout__stats-group-name">
          {{ $t("dashboard.statistics.general_statistics") }}
        </div>
        <div class="dashboard-layout__stats-group-content">
          <div class="dashboard-layout__stats-group-column">
            <span class="dashboard-layout__stats-group-column-name">{{
              $t("dashboard.statistics.enrollments")
            }}</span>
            <span class="dashboard-layout__stats-group-column-value">{{
              $convertToCurrency(statistics.plan.debit)
            }}</span>
          </div>
          <div class="dashboard-layout__stats-group-column">
            <span class="dashboard-layout__stats-group-column-name">{{
              $t("dashboard.statistics.cost")
            }}</span>
            <span class="dashboard-layout__stats-group-column-value">{{
              $convertToCurrency(statistics.plan.credit)
            }}</span>
          </div>
          <div class="dashboard-layout__stats-group-column">
            <span class="dashboard-layout__stats-group-column-name">{{
              $t("dashboard.statistics.remainder")
            }}</span>
            <span class="dashboard-layout__stats-group-column-value">{{
              $convertToCurrency(statistics.plan.balance)
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-layout__content">
      <RouterView />
    </div>
  </div>
</template>

<script>
import ThemeToggler from "./components/ThemeToggler.vue";

export default {
  components: {
    ThemeToggler,
  },
  data() {
    return {
      statistics: {
        plan: {
          debit: 0,
          credit: 0,
          balance: 0,
        },
        completed: {
          debit: 0,
          credit: 0,
          balance: 0,
        },
      },
      menu: {
        isVisible: false,
      },
      checkStatisticsInterval: null,
    };
  },
  computed: {
    isVisibleDashboardStats() {
      return (
        [1, 2].includes(Number(this.$userStore.roleId)) &&
        this.$route?.name !== "personal-account"
      );
    },
  },
  methods: {
    async getStatistics() {
      if (!this.$checkPermissions([1, 2])) return false;
      try {
        const { data } = await this.$api.get("/v2/statistics/all");
        this.statistics = data;
      } catch (e) {
        this.$catch(e, this.$t("dashboard.errors.error_getting_statistics"));
      }
    },
    toggleMenu() {
      this.menu.isVisible = !this.menu.isVisible;
    },
    logout() {
      this.$userStore.name = null;
      this.$userStore.email = null;
      this.$userStore.token = null;
      this.$userStore.roleId = null;
      this.$userStore.userId = null;

      this.$router.push("/");
    },
    async checkStatistics() {
      if (this.$notificationsStore.statistics) {
        await this.getStatistics();
        this.$notificationsStore.statistics = false;
      }
    },
    toggleLocale(locale) {
      if (this.$i18n.locale === locale) return;

      localStorage.setItem("language", locale);

      this.$i18n.locale = locale;
    },
    goToTg () {
      window.location = 'https://t.me/sakora'
    }
  },
  async mounted() {
    await this.getStatistics();
    this.checkStatisticsInterval = setInterval(this.checkStatistics, 1000);
  },
};
</script>

<style src="./style.scss" lang="scss"/>
