<template>
  <button class="theme-toggle" @click="toggleTheme">
    <img
      src="/icons/sun-icon.svg"
      alt="sunny"
      v-if="!$themeStore.isDarkTheme"
      class="icon sunny"
      key="sunny"
    />
    <img
      src="/icons/moon-icon.svg"
      alt="moon"
      v-else
      class="icon moon"
      key="moon"
    />
  </button>
</template>

<script>
export default {
  mounted() {
    this.applyTheme();
  },
  methods: {
    applyTheme() {
      const themeClass = this.$themeStore.isDarkTheme ? "dark-theme" : "light-theme";
      document.body.classList.remove("dark-theme", "light-theme");
      document.body.classList.add(themeClass);
    },
    toggleTheme() {
      this.$themeStore.isDarkTheme = !this.$themeStore.isDarkTheme;
      this.applyTheme();
    },
  },
};
</script>

<style lang="scss">
.theme-toggle {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  overflow: hidden;

  &:focus {
    outline: none;
  }
}

.icon {
  position: relative;
  width: 24px;
  height: 24px;
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.sunny {
    animation: appears 0.3s ease forwards;
  }

  &.moon {
    animation: appears 0.3s ease forwards;
  }

  &:active {
    scale: 0.9;
  }
}

.dark-theme .sunny {
  animation: rotateToDisappear 0.3s ease forwards;
}

.light-theme .moon {
  animation: rotateToDisappear 0.3s ease forwards;
}
@keyframes rotateToDisappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes appears {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    z-index: 1000;
  }
}
</style>
