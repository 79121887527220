<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window">
      <div class="ui-modal__heading">
        <h3>{{ $t("create_credit_modal.adding_an_expense") }}</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form class="ui-modal__form" @submit.prevent="createCredit">
        <div class="ui-form-group">
          <label class="ui-label">{{ $t("create_credit_modal.date") }}</label>
          <vue-date-picker
            locale="ru"
            v-model="credit.createdAt"
            :dark="$themeStore.isDarkTheme"
          />
        </div>
        <div
          v-if="multiselect.creditTypes.length"
          class="ui-form-group"
        >
          <label class="ui-label">{{
            $t("create_credit_modal.flow_type")
          }}</label>
          <multi-select-wr
            v-model="multiselect.creditTypeObject"
            :placeholder="$t('create_credit_modal.select_flow_type')"
            label="name"
            track-by="id"
            :options="multiselect.creditTypes"
            @select="selectCreditType"
            @remove="removeCreditType"
            required
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">{{
            $t("create_credit_modal.expense_name")
          }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('create_credit_modal.expense_name')"
            v-model="credit.name"
            required
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">{{
            $t("create_credit_modal.expense_amount")
          }}</label>
          <input
            type="number"
            class="ui-input"
            :placeholder="$t('create_credit_modal.expense_amount')"
            v-model="credit.sum"
            min="0"
            step="0.01"
            required
          />
        </div>
        <div class="ui-form-group" v-if="multiselect.creditStatuses.length">
          <label class="ui-label">{{ $t("create_credit_modal.status") }}</label>
          <multi-select-wr
            v-model="multiselect.creditStatusObject"
            :placeholder="$t('create_credit_modal.select_status')"
            label="name"
            track-by="id"
            :options="multiselect.creditStatuses"
            @select="selectCreditStatus"
            @remove="removeCreditStatus"
            required
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">{{ $t("create_credit_modal.check") }}</label>
          <file-input
            :value="credit.attachment"
            @select-file="selectFile"
          />
        </div>
        <button
          type="submit"
          class="ui-button"
          v-text="$t('create_credit_modal.save')"
        />
      </form>
    </div>
  </div>
</template>

<script>
import MultiSelectWr from "@/global-components/MultiSelectWr.vue";
import FileInput from "@/global-components/FileInput.vue";
import VueDatePicker from "@/main";

export default {
  components: { VueDatePicker, MultiSelectWr, FileInput },
  props: ["eventId"],
  data() {
    return {
      multiselect: {
        creditStatuses: [],
        creditStatusObject: null,
        creditTypes: [],
        creditTypeObject: -1,
      },
      credit: {
        eventId: this.$props.eventId,
        type: "OTHER",
        name: null,
        sum: null,
        percent: null,
        creditStatusId: null,
        creditTypeId: null,
        createdAt: Date.now(),
        attachment: null
      },
      creditStatuses: [],
      creditTypes: [],
      isLoading: false,
    };
  },
  async mounted() {
    await Promise.all([this.getCreditStatuses(), this.getCreditTypes()]).then(
      () => {
        // Multiselect :: map statuses
        this.multiselect.creditStatuses = this.creditStatuses.map((status) => {
          return {
            id: status.id,
            name: status.name,
          };
        });

        // Multiselect :: map credit types
        this.multiselect.creditTypes = this.creditTypes.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      }
    );
    await this.showInfoMessage()
  },
  methods: {
    selectCreditStatus(status) {
      this.credit.creditStatusId = status.id;
    },
    removeCreditStatus() {
      this.credit.creditStatusId = null;
    },
    selectFile (file) {
      if (!file?.name) {
        this.credit.attachment = null

        return
      }

      this.credit.attachment = file
    },
    async getCreditStatuses() {
      try {
        const { data } = await this.$api.get("/v1/credit-status/list");
        this.creditStatuses = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("create_credit_modal.errors.error_receiving_data")
        );
      }
    },
    selectCreditType(creditType) {
      this.credit.creditTypeId = creditType.id;
    },
    removeCreditType() {
      this.credit.creditTypeId = null;
    },
    async getCreditTypes() {
      try {
        const { data } = await this.$api.get("/v1/credit-type/list");
        this.creditTypes = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("create_credit_modal.errors.error_getting_statuses")
        );
      }
    },
    createCredit() {
      const messages = this.checkMessages() || []

      if (messages.length) {
        this.showInfoMessage()

        return
      }

      if (this.isLoading) return false;
      this.isLoading = true;

      // 2024-03-26 09:46:10
      const date = new Date(this.credit.createdAt)
      const createdAt = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

      const data = {
        ...this.credit,
        createdAt
      }

      console.log('data', data)

      this.$api
        .post(`/v1/credit/create`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$toast(
            "success",
            this.$t("create_credit_modal.statuses.expense_added")
          );
          this.$emit("success");
        })
        .catch((e) => {
          this.$catch(e, this.$t("create_credit_modal.errors.error_update"));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    checkMessages () {
      const messages = []

      if (!this.multiselect.creditTypes.length) {
        messages.push(this.$t('create_credit_modal.errors.add_expense_type'))
      }

      return messages
    },
    async showInfoMessage () {
      const messages = this.checkMessages() || []

      messages.forEach((text) => {
        this.$toast('error', text)
      })
    }
  }
};
</script>
