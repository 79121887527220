<template>
  <div>
    <a
      v-if="file"
      :href="`${$backendUrl}/${file.file}`"
      class="ui-attachment ui-modal__attachment"
      target="_blank"
    >
      {{ $t("create_credit_modal.attached_file") }} {{ file?.name }}
    </a>
    <input
      type="file"
      accept=".jpg,.png,.jpeg"
      @input="select"
    />
    <span v-if="error" class="error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: "FileInput",
  data() {
    return {
      error: '',
      file: this.value
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    backendUrl: {
      type: String,
      default: ''
    }
  },
  watch: {
    value(newValue) {
      this.file = newValue
    }
  },
  methods: {
    select(e) {
      this.error = ''

      const file = e.target.files[0]
      const maxSizeImMBite = 3

      // Если больше 3 мегабайт
      if (file?.size && file.size / 1000000 > maxSizeImMBite) {
        this.error = this.$t("create_credit_modal.errors.attachment_error")

        this.file = null

        this.$emit('select-file', null)

        return
      }

      this.$emit('select-file', file)
    }
  }
};
</script>
