<template>
  <div class="tariff">
    <div class="tariff-block__grid-1">
      <div class="widget">
        <h3>{{ $t('personal_account.info.title_tariff') }}</h3>

        <table>
          <tr>
            <td>{{ $t('personal_account.info.your_tariff') }}</td>
            <td>{{ $t('personal_account.info.event_count') }}</td>
          </tr>
          <tr>
            <td>{{ getTariffInfo?.title }}</td>
            <td>{{ tariff?.eventsCount }}</td>
          </tr>
        </table>
      </div>
    </div>

    <button @click="changeTariff" class="tariff-button ui-button" v-text="$t('personal_account.info.change_tariff')" />

    <PaymentModal
      v-if="isVisiblePaymentModal"
      @closeModal="closePaymentModal"
      @success="closePaymentModal"
    />
  </div>
</template>

<script>
import PaymentModal from "@/components/modals/payment-modal/PaymentModal.vue";

export default {
  data() {
    return {
      tariff: {},
      tariffs: [],
      isVisiblePaymentModal: false
    }
  },
  components: {
    PaymentModal
  },
  computed: {
    getTariffInfo () {
      return this.tariffs.find(({ id }) => id === this.tariff.tariffId)
    }
  },
  created () {
    this.loadInfoTariff()
    this.loadAllTariffs()
  },
  methods: {
    async loadInfoTariff () {
      try {
        const { data } = await this.$api.get(`/v1/tariff-history/by-id/${this.$userStore.userId}`)

        this.tariff = data
      } catch(e) {
        this.$catch(e, this.$t('personal_account.form.personal_information.error.get'))
      }
    },
    async loadAllTariffs () {
      try {
        const { data } = await this.$api.get(`/v1/tariff/list`)

        this.tariffs = data
      } catch(e) {
        this.$catch(e, this.$t('personal_account.form.personal_information.error.get'))
      }
    },
    changeTariff () {
      this.isVisiblePaymentModal = true
    },
    closePaymentModal () {
      this.isVisiblePaymentModal = false
    },
    changeData () {
      if (this.isLoading) return false
      this.isLoading = true

      this.$api
        .post('/v1/contact/create', {
          name: this.user.name,
          phone: this.user.phone,
          address: this.user.address,
          company: this.user.company,
          description: this.user.description
        })
        .catch(e => {
          this.$catch(e, this.$t('personal_account.form.personal_information.error.change'))
        })
        .finally(() => {
          this.inProgress = false
        })
    }
  }
}
</script>
