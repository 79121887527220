<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window" v-show="componentIsReady">
      <div class="ui-modal__heading">
        <h3>{{ $t("edit_credit_modal.editing_consumption") }}</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form class="ui-modal__form" @submit.prevent="updateCredit">
        <div class="ui-form-group">
          <label class="ui-label">{{ $t("edit_credit_modal.date") }}</label>
          <vue-date-picker
            locale="ru"
            v-model="credit.createdAt"
            :dark="$themeStore.isDarkTheme"
          />
        </div>
        <div class="ui-form-group" v-if="multiselect.creditTypes.length">
          <label class="ui-label">{{
            $t("edit_credit_modal.flow_type")
          }}</label>
          <multi-select-wr
            v-model="multiselect.creditTypeObject"
            :placeholder="$t('edit_credit_modal.select_flow_type')"
            label="name"
            track-by="id"
            :options="multiselect.creditTypes"
            @select="selectCreditType"
            required
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">{{
            $t("edit_credit_modal.expense_name")
          }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('edit_credit_modal.expense_name')"
            v-model="credit.name"
            required
          />
        </div>
        <div class="ui-form-group" v-if="credit.type === 'COMISSION'">
          <label class="ui-label">{{
            $t("edit_credit_modal.system_commission")
          }}</label>
          <input
            type="number"
            class="ui-input"
            :placeholder="$t('edit_credit_modal.system_commission')"
            v-model="credit.percent"
            min="0"
            max="100"
            step="0.01"
            required
          />
        </div>
        <div class="ui-form-group" v-else>
          <label class="ui-label">{{
            $t("edit_credit_modal.expense_amount")
          }}</label>
          <input
            type="number"
            class="ui-input"
            :placeholder="$t('edit_credit_modal.expense_amount')"
            v-model="credit.sum"
            min="0"
            step="0.01"
            required
          />
        </div>
        <div class="ui-form-group" v-if="creditStatuses.length">
          <label class="ui-label">{{ $t("edit_credit_modal.status") }}</label>
          <multi-select-wr
            v-model="multiselect.creditStatusObject"
            :placeholder="$t('edit_credit_modal.select_status')"
            label="name"
            track-by="id"
            :options="multiselect.creditStatuses"
            @select="selectCreditStatus"
            @remove="removeCreditStatus"
            required
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">{{ $t("edit_credit_modal.check") }}</label>
          <a
            v-if="credit.attachment"
            :href="`${$backendUrl}/${credit.attachment.file}`"
            class="ui-attachment ui-modal__attachment"
            target="_blank"
          >
            {{ $t("edit_credit_modal.attached_file") }}
          </a>
          <input
            type="file"
            accept=".jpg,.png,.jpeg,.pdf"
            @input="credit.attachment = $event.target.files[0]"
          />
        </div>
        <button
          type="submit"
          class="ui-button"
          v-text="$t('edit_credit_modal.save')"
        />
      </form>
    </div>
  </div>
</template>

<script>
import MultiSelectWr from "@/global-components/MultiSelectWr.vue";

export default {
  components: { MultiSelectWr },
  props: ["creditId"],
  data() {
    return {
      multiselect: {
        creditStatusObject: null,
        creditStatuses: [],
        creditTypes: [],
        creditTypeObject: null,
      },
      componentIsReady: false,
      credit: {
        type: null,
        name: null,
        sum: null,
        percent: null,
        creditStatusId: null,
        creditTypeId: null,
        createdAt: null,
        attachment: null,
      },
      creditStatuses: [],
      creditTypes: [],
      isLoading: false,
    };
  },
  methods: {
    selectCreditStatus(status) {
      this.credit.creditStatusId = status.id;
    },
    removeCreditStatus() {
      this.credit.creditStatusId = null;
    },
    async getCredit() {
      try {
        const { data } = await this.$api.get(
          `/v1/credit/get/${this.$props.creditId}`
        );
        this.credit = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("edit_credit_modal.errors.error_receiving_data")
        );
      }
    },
    async getCreditStatuses() {
      try {
        const { data } = await this.$api.get("/v1/credit-status/list");
        this.creditStatuses = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("edit_credit_modal.errors.error_receiving_data")
        );
      }
    },
    async getCreditTypes() {
      try {
        const { data } = await this.$api.get("/v1/credit-type/list");
        this.creditTypes = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("edit_credit_modal.errors.error_getting_expense_statuses")
        );
      }
    },
    updateCredit() {
      if (this.isLoading) return false;
      this.isLoading = true;

      this.$api
        .post(`/v1/credit/update/${this.$props.creditId}`, this.credit, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$toast(
            "success",
            this.$t("edit_credit_modal.statuses.data_updated")
          );
          this.$emit("success");
        })
        .catch((e) => {
          this.$catch(e, this.$t("edit_credit_modal.errors.error_data_update"));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectCreditType(creditType) {
      this.credit.creditTypeId = creditType.id;
    },
  },
  mounted() {
    Promise.all([
      this.getCreditStatuses(),
      this.getCreditTypes(),
      this.getCredit(),
    ])
      .then(() => {
        // Multiselect :: credit statuses
        this.multiselect.creditStatuses = this.creditStatuses.map((status) => {
          return {
            id: status.id,
            name: status.name,
          };
        });

        this.multiselect.creditStatusObject =
          this.multiselect.creditStatuses.filter((status) => {
            if (status.id === this.credit.creditStatusId) return status;
          });

        if (this.multiselect.creditStatusObject.length)
          this.multiselect.creditStatusObject =
            this.multiselect.creditStatusObject[0];

        // Multiselect :: credit types
        this.multiselect.creditTypes = this.creditTypes.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });

        this.multiselect.creditTypeObject = this.creditTypes.filter((item) => {
          if (item.id === this.credit.creditTypeId) return item;
        });

        if (this.multiselect.creditTypeObject.length)
          this.multiselect.creditTypeObject =
            this.multiselect.creditTypeObject[0];
      })
      .then(() => {
        this.componentIsReady = true;
      });
  },
};
</script>
