<template>
    <div class="db-personal-account-view">
        <div class="db-personal-account-view__tabs">
            <div
                class="db-personal-account-view__tab"
                :class="{ 'db-personal-account-view__tab-active': activeTab === code }"
                v-for="{ code, title } in filteredTabs"
                :key="code"
                @click="selectTab(code)"
            >
                {{ $t(title) }}
            </div>
        </div>

        <div class="db-personal-account-view-form">
          <component
            :is="activeTab"
          />
        </div>
    </div>
</template>

<script>
import PersonalInformation from './components/forms/PersonalInformation.vue'
import InfoTariff from './components/info/InfoTariff.vue'

const TABS = [
  {
    title: 'personal_account.personal_information',
    code: 'personal-information',
    permission: [1, 2, 3, 4]
  },
  {
    title: 'personal_account.tariff',
    code: 'info-tariff',
    permission: [1]
  }
]

export default {
    components: {
      PersonalInformation,
      InfoTariff
    },
    data() {
       return {
         activeTab: 'personal-information'
        }
    },
    computed: {
        filteredTabs() {
          return TABS.filter(({ permission }) => {
            if (permission.includes(Number(this.$userStore.roleId))) {
              return true
            }
          })
        }
    },
    methods: {
      selectTab(tab) {
        this.activeTab = tab;
      }
    }
}
</script>

<style src="./style.scss" lang="scss"/>
