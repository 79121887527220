<template>
  <div class="tasks" v-if="isInited">
    <section class="tasks-main">
      <button
        class="tasks-main__add ui-button ui-button-auto"
        @click="newTaskToday"
      >
        {{ $t("tasks.actions.addNew") }}
      </button>
    </section>
    <week-information @refetch="refetch" />
    <section class="tasks-zone">
      <div
        :key="column"
        class="tasks-column"
        v-for="(column, index) in columns"
        :data-column-index="index"
      >
        <div class="tasks-column__header">
          <span class="quantity" :data-quantity="tasks[column]?.length">
            {{ tasks[column]?.length }}
          </span>
          <h2 class="title">
            {{ column }}
          </h2>
        </div>
        <draggable
          v-if="!nonDraggableColumns.includes(column)"
          class="tasks-column__body"
          :list="tasks[column]"
          group="tasks"
          itemKey="id"
          :key="column"
          ghost-class="drag-ghost"
          chosen-class="drag-chosen"
          filter=".no-drag"
          preventOnFilter
          @end="updateTaskOrder"
        >
          <template #item="{ element }">
            <task-component
              :task="element"
              class="drag"
              @open-modal="startEditTask"
              @dropdown-select="dropdownItemClicked"
              @status-changed="completeTask"
            />
          </template>
        </draggable>
        <div v-else class="tasks-column__body">
          <task-component
            v-for="task in tasks[column]"
            :key="task.id"
            :task="task"
            class="no-drag"
            @open-modal="startEditTask"
            @dropdown-select="dropdownItemClicked"
            @status-changed="completeTask"
          />
        </div>

        <add-new-task-button
          @click="openModal(column)"
          class="tasks-column__footer"
        />
      </div>
    </section>
    <modal-component
      :visible="isModalOpen"
      :type="modalType"
      @close="closeModal"
      @add-task="addTask"
      @update-task="updateTask"
      :task="currentTask"
      :users="users"
      :date="currentDay"
    />
    <confirmation-dialog ref="confirmationDialog" />
  </div>
  <preloader v-else class="loader">
    <span class="loader__text">{{ $t("tasks.other.loading") }}</span>
  </preloader>
</template>

<script>
import Preloader from "@/global-components/Preloader.vue";
import draggable from "vuedraggable";
import ConfirmationDialog from "../components/confirmation/ConfirmationDialog.vue";
import AddNewTaskButton from "./components/AddNewTaskButton.vue";
import ModalComponent from "./components/ModalComponent.vue";
import TaskComponent from "./components/TaskComponent.vue";
import WeekInformation from "./components/WeekInformation.vue";
import { DAYS_OF_WEEK } from "./constants/Days";
import {
  CREATE_TASK,
  DELETE_TASK,
  GET_ALL_COMPLETED_TASKS,
  GET_ALL_TASKS,
  GET_ALL_USERS,
  UPDATE_TASK,
} from "./constants/Endpoint";

export default {
  components: {
    ModalComponent,
    TaskComponent,
    AddNewTaskButton,
    draggable,
    Preloader,
    ConfirmationDialog,
    WeekInformation,
  },
  data() {
    return {
      daysOfWeek: DAYS_OF_WEEK,
      columns: [],
      tasks: [],
      currentDay: "",
      isInited: false,
      currentTask: null,
      users: [],
      modalType: undefined,
      nonDraggableColumns: ["Completed"],
      currentWeek: 0,
    };
  },
  computed: {
    findTaskById(taskId) {
      for (const day in this.tasks) {
        const task = this.tasks[day].find((task) => task.id === taskId);
        if (task) return task;
      }
      return null;
    },
    formattedDates() {
      const WEEK_DAYS_LENGTH = 7;
      const currentDate = new Date();
      const currentDay = (currentDate.getDay() + 6) % WEEK_DAYS_LENGTH;
      const targetMonday = new Date(currentDate);
      targetMonday.setDate(
        currentDate.getDate() - currentDay + this.currentWeek * WEEK_DAYS_LENGTH
      );

      const weekDates = [];
      for (let i = 0; i < WEEK_DAYS_LENGTH; i++) {
        const date = new Date(targetMonday);
        date.setDate(targetMonday.getDate() + i);
        date.setHours(1, 0, 0, 0);
        weekDates.push(date);
      }

      return weekDates;
    },
    generateColumns() {
      return [
        ...this.formattedDates.map((date, index) => {
          const dayOfWeek = DAYS_OF_WEEK[index];
          return `${this.$t(`tasks.columns.${dayOfWeek}`)}, ${date
            .toISOString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join(".")}`;
        }),
        "Completed",
      ];
    },
  },
  methods: {
    isTaskDateThisWeek(task) {
      const taskDueDate = new Date(task.dueDate);
      taskDueDate.setHours(1, 0, 0, 0);
      return this.formattedDates.some((date) => {
        return taskDueDate.getTime() === date.getTime();
      });
    },
    openModal(day) {
      this.closeModal();
      this.currentDay = day;
      this.modalType = "create";
      console.log({ day });
    },
    formatDueDate(newDate) {
      const date = new Date(newDate);
      date.setHours(1, 0, 0, 0);
      const dayOfWeek = DAYS_OF_WEEK[date.getDay() - 1];
      return `${this.$t(`tasks.columns.${dayOfWeek}`)}, ${date
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join(".")}`;
    },
    newTaskToday() {
      const formattedDate = this.formatDueDate(new Date());
      this.openModal(formattedDate);
    },
    closeModal() {
      this.currentDay = "";
      this.currentTask = null;
      this.modalType = undefined;
    },
    startViewTask(task, mode) {
      this.modalType = mode;
      this.currentTask = task;
      this.currentDay = this.formatDueDate(task.dueDate)
    },
    dropdownItemClicked([item], task) {
      if (item === "edit") this.startViewTask(task, "edit");
      else if (item === "delete") this.deleteTask(task);
      else if (item === "view") this.startViewTask(task, "view");
    },
    async updateTaskOrder(event) {
      const { from, to, item } = event;

      const fromColumnIndex = Array.from(
        document.querySelectorAll(".tasks-column__body")
      ).indexOf(from);
      const toColumnIndex = Array.from(
        document.querySelectorAll(".tasks-column__body")
      ).indexOf(to);

      const taskData = item.__draggable_context.element;
      if (fromColumnIndex === toColumnIndex) return;
      taskData.weekday = toColumnIndex;
      taskData.dueDate = this.formattedDates[toColumnIndex].toISOString();
      await this.$api.put(UPDATE_TASK, taskData);
    },
    async getTasks(week = 0) {
      const { data } = await this.$api.get(`${GET_ALL_TASKS}?week=${week}`);

      this.sortTasksByColumns(data.tasks);
    },
    sortTasksByColumns(tasks) {
      const sortedTasks = {};

      sortedTasks.Completed = this.tasks.Completed || [];
      this.columns.slice(0, -1).forEach((column, i) => {
        const date = this.formattedDates[i].toISOString().split("T")[0];
        sortedTasks[column] = tasks.filter((task) => {
          const taskDueDate = new Date(task.dueDate)
            .toISOString()
            .split("T")[0];
          return taskDueDate === date;
        });
      });

      this.tasks = sortedTasks;
    },
    async refetch(week, force = false) {
      if (!force && week === this.currentWeek) return;
      const completedTasks = this.tasks.Completed || [];
      this.tasks = { Completed: completedTasks };
      this.currentWeek = week;
      this.columns = this.generateColumns;
      await this.getTasks(week);
    },
    async getCompletedTasks() {
      const { data } = await this.$api.get(GET_ALL_COMPLETED_TASKS);
      this.tasks["Completed"] = data.tasks;
    },
    async addTask(proxy) {
      const taskFromData = JSON.parse(JSON.stringify(proxy.task));
      const dayIndex = DAYS_OF_WEEK.findIndex((day) => day === this.currentDay);
      const newTask = {
        ...taskFromData,
        weekday: dayIndex,
        userId: this.$userStore.userId,
      };
      await this.$api.post(CREATE_TASK, newTask);

     
      this.modalType = undefined;
      this.currentDay = "";
      this.refetch(this.currentWeek, true);
    },
    async updateTask(proxy) {
      const taskFromData = JSON.parse(JSON.stringify(proxy.task));

      try {
        await this.$api.put(UPDATE_TASK, {
          ...taskFromData,
          user: undefined,
        });
        this.modalType = undefined;
        this.currentDay = "";
        this.refetch(this.currentWeek, true);
        this.$toast("success", this.$t("tasks.toast.updatedSuccessfully"));
      } catch (error) {
        console.log("Error updating task", error);
        this.$toast("error", this.$t("tasks.toast.updateFailed"));
      }
    },
    updateTaskById(taskId, updatedFields) {
      for (const day in this.tasks) {
        const taskIndex = this.tasks[day].findIndex(
          (task) => task.id === taskId
        );
        if (taskIndex !== -1) {
          this.tasks[day][taskIndex] = {
            ...this.tasks[day][taskIndex],
            ...updatedFields,
          };
          this.tasks[day][taskIndex].updatedAt = new Date().toISOString();
          return this.tasks[day][taskIndex];
        }
      }
      return null;
    },
    async completeTask(task) {
      try {
        await this.$api.put(UPDATE_TASK, { ...task, isDone: true });
      } catch (error) {
        console.error("Error completing task", error);
        this.$toast("error", this.$t("tasks.toast.updateFailed"));
      }
    },
    removeTaskById(taskId) {
      for (const day in this.tasks) {
        const taskIndex = this.tasks[day].findIndex(
          (task) => task.id === taskId
        );
        if (taskIndex !== -1) {
          this.tasks[day].splice(taskIndex, 1);
        }
      }
    },
    async deleteTask(task) {
      const ok = await this.$refs.confirmationDialog.show({
        title: this.$t("single_event.messages.confirm_deletion"),
        content: this.$t("tasks.other.confirmDeletion"),
      });

      if (!ok) return;
      try {
        const id = task.id;
        await this.$api.delete(DELETE_TASK + `/${id}`);
        this.updateTaskById(id, { isDeleted: true });
        this.$toast("success", this.$t("tasks.toast.deletedSuccessfully"));
      } catch (e) {
        this.$toast("error", this.$t("tasks.toast.deleteFailed"));
      }
    },
    async getUsers() {
      try {
        const { data } = await this.$api.get(GET_ALL_USERS);
        this.users = data.map((user) => ({
          ...user,
          name: `${user.role.name}: ${user.contact.name} (${user.email})`,
        }));
      } catch (error) {
        console.error("Error fetching users", error);
      }
    },
  },
  async mounted() {
    this.columns = this.generateColumns;
    await this.getTasks();
    await this.getCompletedTasks();
    await this.getUsers();
    this.isInited = true;
  },
};
</script>

<style lang="scss" src="./TaskManagerView.scss" scoped />
