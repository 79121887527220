<template>
  <div class="addition-main-wrapper">
    <addition-user />
  </div>
</template>

<script>
import AdditionUser from "./components/AdditionUser.vue"

export default {
  components: {
    AdditionUser
  }
}
</script>
