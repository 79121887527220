<template>
  <div class="db-main-view">
    <div class="db-main-view__buttons">
      <div class="db-main-view__buttons-group">
        <div>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('events_list.search')"
            v-model="filters.searchText"
            @keyup="filterEvents"
          />
        </div>
        <div class="db-main-view__filters">
          <button
            class="ui-button db-main-view__filters-btn"
            v-text="
              `${$t('events_list.filters')} (${
                filters.eventStatuses.length + filters.creditStatuses.length
              })`
            "
            @click.prevent="filters.isOpen = !filters.isOpen"
          />

          <div class="db-main-view__filters-container" v-if="filters.isOpen">
            <div
              class="db-main-view__filters-container__group"
              v-if="filtersData.eventStatuses.length"
            >
              <p class="db-main-view__filters-container__group-name">
                {{ $t('events_list.event_status') }}
              </p>
              <div
                class="ui-checkbox-group"
                v-for="status in filtersData.eventStatuses"
                :key="status.id"
              >
                <input
                  type="checkbox"
                  v-model="filters.eventStatuses"
                  :value="status.id"
                  :id="`event_status_${status.id}`"
                  @change="filterEvents"
                />
                <label
                  :for="`event_status_${status.id}`"
                  v-text="$t(status.name)"
                />
              </div>
            </div>

            <div
              class="db-main-view__filters-container__group"
              v-if="filtersData.creditStatuses.length"
            >
              <p class="db-main-view__filters-container__group-name">
                {{ $t('events_list.operation_status') }}
              </p>
              <div
                class="ui-checkbox-group"
                v-for="status in filtersData.creditStatuses"
                :key="status.id"
              >
                <input
                  type="checkbox"
                  v-model="filters.creditStatuses"
                  :value="status.id"
                  :id="`credit_status_${status.id}`"
                  @change="filterEvents"
                />
                <label
                  :for="`credit_status_${status.id}`"
                  v-text="$t(status.name)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="db-main-view__buttons-group" v-if="$checkPermissions([1, 2])">
        <button
          class="db-main-view__create-btn"
          @click.prevent="openNewEventModal"
        >
          <span v-if="isLoadingModal">{{ $t('events_list.loading') }}</span>
          <span v-else>{{ $t('events_list.create_event') }}</span>
        </button>
      </div>
    </div>

    <div class="db-main-view__list" v-if="eventsF.length">
      <div
        v-for="e in eventsF"
        :key="e.id"
        :class="{
          'db-main-view__item': true,
          'db-main-view__item--active': e.isOpen,
        }"
      >
        <!--SingleEvent-->
        <SingleEvent
          :key="e.id"
          :event="e"
          @openEvent="e.isOpen = !e.isOpen"
          @updateEvent="updateEvents()"
        />

        <!--Debits and credits-->
        <SingleEventOperations v-if="e.isOpen" @updateEventFromChild="updateEvents()" :eventId="e.id" />
      </div>
    </div>

    <CreateEventModal
      v-if="newEvent.isOpen"
      @closeModal="closeNewEventModal"
      @success="successNewEventModal"
    />

    <PaymentModal
      v-if="isVisiblePaymentModal"
      @closeModal="closePaymentModal"
      @success="closePaymentModal"
    />
  </div>
</template>

<script>
import PaymentModal from "@/components/modals/payment-modal/PaymentModal.vue";
import CreateEventModal from "./components/create-event/CreateEventModal.vue";
import SingleEvent from './components/events-list/SingleEvent.vue';
import SingleEventOperations from "./components/events-list/SingleEventOperations.vue";

export default {
  components: {
    SingleEvent,
    SingleEventOperations,
    CreateEventModal,
    PaymentModal
  },
  data() {
    return {
      eventsF: [],
      events: [],

      // filters
      filters: {
        searchText: "",
        eventStatuses: [],
        creditStatuses: [],
        isOpen: false,
      },
      filtersData: {
        eventStatuses: [],
        creditStatuses: [],
      },

      // new event
      newEvent: {
        isOpen: false,
      },
      isVisiblePaymentModal: false,
      isLoadingModal: false
    }
  },
  mounted() {
    Promise.all([
      this.getEvents(),
      this.getEventStatuses(),
      this.getCreditStatuses()
    ])

    this.showToast()
  },
  methods: {
    async getEvents() {
      try {
        const { data } = await this.$api.get('/v2/event/list')
        data.map(item => { item.isOpen = false })
        this.events = data
        this.filterEvents()
      } catch (e) {
        this.$catch(e, this.$t('events_list.errors.failed_retrieve_event_data_from_server'))
      }
    },
    filterEvents() {
      this.eventsF = this.events;

      if (this.filters.searchText.length > 1) {
        this.eventsF = this.eventsF.filter((item) => {
          if (item.name.toLowerCase().indexOf(this.filters.searchText.toLowerCase()) > -1) return item;
        });
      }

      if (this.filters.eventStatuses.length) {
        this.eventsF = this.eventsF.filter((item) => {
          if (this.filters.eventStatuses.indexOf(item.status.id) > -1) return item;
        });
      }

      if (this.filters.creditStatuses.length) {
        this.eventsF = this.eventsF.filter((item) => {
          for (let singleCredit of item.credits) {
            if (this.filters.creditStatuses.indexOf(singleCredit.status.id) > -1) return item;
          }
        });
      }
    },
    async getEventStatuses() {
      try {
        const { data } = await this.$api.get("/v1/event-status/list");
        this.filtersData.eventStatuses = data;
      } catch (e) {
        this.$catch(e, this.$t('events_list.errors.failed_get_event_statuses'));
      }
    },
    async getCreditStatuses() {
      try {
        const { data } = await this.$api.get("/v1/credit-status/list");
        this.filtersData.creditStatuses = data;
      } catch (e) {
        this.$catch(e, this.$t('events_list.errors.failed_get_operation_statuses'));
      }
    },
    async openNewEventModal() {
      if (this.isLoadingModal) {
        return
      }

      this.isLoadingModal = true

      const { data } = await this.$api.get(`/v2/event/is-allowed-create/${this.$userStore.userId}`);

      if (data.status !== 'allowed') {
        this.isVisiblePaymentModal = true

        return
      }

      await this.$nextTick()

      this.newEvent.isOpen = true
      this.isLoadingModal = false
    },
    closeNewEventModal() {
      this.newEvent.isOpen = false
    },
    closePaymentModal() {
      this.isVisiblePaymentModal = false
      this.isLoadingModal = false
    },
    successNewEventModal() {
      this.getEvents()
      this.closeNewEventModal()
    },
    async updateEvents() {
      let openedEventsIds = this.eventsF.filter(e => e.isOpen).map(e => e.id)
      await this.getEvents()
      this.filterEvents()
      this.eventsF.forEach(e => {
        if (openedEventsIds.indexOf(e.id) > -1) e.isOpen = true
      })
    },
    showToast() {
      const toastData = localStorage.getItem("showToast")

      if (toastData) {
        this.$toast("success", this.$t("auth.statuses.given_ten_free_events"), 1000);

        localStorage.removeItem("showToast")
      }
    }
  }
}
</script>

<style src="./style.scss" lang="scss"/>
