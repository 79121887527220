<template>
  <button class="button" v-bind="$attrs">
    <img :src="getIconSrc()" @error="handleImageError" width="24" height="24" />
  </button>
</template>

<script>
import { ICON_BUTTON_CONTENT } from "../constants/IconButtonContent";

export default {
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      required: true,
      default: "plus",
      validator(value) {
        return ICON_BUTTON_CONTENT.has(value);
      },
    }
  },
  methods: {
    getIconSrc() {
      const icon = ICON_BUTTON_CONTENT.get(this.variant).icon;
      return icon ? icon : "/icons/test.jpg";
    },
    handleImageError(event) {
      event.target.src = "/icons/test.jpg";
    },
  },
};
</script>

<style lang="scss" scoped src="./IconButton.scss" />
