import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";

export const useThemeStore = defineStore("theme", {
  state: () => {
    return {
      isDarkTheme: useStorage("theme", false),
    };
  },
});
