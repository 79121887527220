<template>
    <div class="ui-modal">
        <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
        <div class="ui-modal__window">
            <div class="ui-modal__heading">
                <h3>{{ $t('edit_user_modal.editing_user') }}</h3>
                <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
            </div>

            <form class="ui-modal__form" @submit.prevent="updateUser">
                <div class="ui-form-group">
                    <label class="ui-label">{{ $t('edit_user_modal.name') }}</label>
                    <input
                        type="text"
                        class="ui-input"
                        :placeholder="$t('edit_user_modal.name')"
                        v-model="user.contact.name"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">{{ $t('edit_user_modal.email') }}</label>
                    <input
                        type="email"
                        class="ui-input"
                        :placeholder="$t('edit_user_modal.email')"
                        v-model="user.email"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">{{ $t('edit_user_modal.phone_number') }}</label>
                    <phone-input
                      :placeholder="$t('edit_user_modal.phone_number')"
                      :value="user.contact.phone"
                      @input="inputPhone"
                    />
                </div>
                <!-- Только для клиента -->
                <div class="ui-form-group" v-if="user.role.name === 'Клиент'">
                    <label class="ui-label">{{ $t('edit_user_modal.company_name') }}</label>
                    <input
                        type="text"
                        class="ui-input"
                        :placeholder="$t('edit_user_modal.company_name')"
                        v-model="user.contact.company"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">{{ $t('edit_user_modal.address') }}</label>
                    <input
                        type="text"
                        class="ui-input"
                        :placeholder="$t('edit_user_modal.address')"
                        v-model="user.contact.address"
                        required
                    />
                </div>

                <button type="submit" class="ui-button" v-text="$t('edit_user_modal.save')" />
            </form>
        </div>
    </div>
</template>

<script>
import PhoneInput from "@/global-components/PhoneInput.vue";

export default {
    components: {
        PhoneInput
    },
    props: ['userId'],
    data() {
        return {
            user: {
                id: null,
                email: null,
                contact: {
                    name: null,
                    phone: '',
                    address: null
                },
                organization: {
                    name: null
                },
                role: {
                    id: null,
                    name: null
                }
            },
            isLoading: false
        }
    },
    methods: {
        inputPhone(e) {
            this.user.contact.phone = e.target.value
        },
        updateUser() {
            if (this.isLoading) return false
            this.isLoading = true

            this.$api
                .post(`/v1/user/update/${this.user.id}`, this.user)
                .then(() => {
                    this.$toast('success', this.$t('edit_user_modal.messages.user_details_updated'))
                    this.$emit('success')
                })
                .catch(e => {
                    this.$catch(e, this.$t('edit_user_modal.errors.error_updating_user_data'))
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        // get user data
        async getUser() {
            try {
                let {data} = await this.$api.get(`/v1/user/get/${this.$props.userId}`)
                this.user = data
            } catch(e) {
                this.$catch(e, this.$t('edit_user_modal.errors.error_retrieving_user_data_try_again'))
            }
        }
    },
    async mounted() {
        await this.getUser()
    }
}
</script>
