<template>
  <multi-select
    :value="value"
    :tag-placeholder="tagPlaceholder || placeholder"
    :placeholder="placeholder"
    :label="label"
    :custom-label="nameWithLang"
    :track-by="trackBy"
    :options="options"
    :multiple="multiple"
    :searchable="searchable"
    :selectLabel="$t('multi_select_wr.choose')"
    :deselectLabel="$t('multi_select_wr.cancel')"
    :selectedLabel="$t('multi_select_wr.selected')"
    :allow-empty="allowEmpty"
    :showLabels="false"
    @select="select"
    @remove="remove"
    :required="required"
  >
    <template #noOptions>
      <span>{{ $t('multi_select_wr.empty') }}</span>
    </template>

    <template #noResult>
      <span>{{ $t('multi_select_wr.nothing_found') }}</span>
    </template>
  </multi-select>
</template>

<script>
import MultiSelect from "@/main";

export default {
  components: {MultiSelect},
  props: {
    select: {
      type: Function,
      default: () => {}
    },
    remove: {
      type: Function,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: ''
    },
    tagPlaceholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    trackBy: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  methods: {
    nameWithLang(data) {
      const text = data[this.label]

      if (!text) {
        return
      }

      return this.$t(text)
    }
  }
}
</script>