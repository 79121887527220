<template>
  <div class="dropdown">
    <icon-button
      @click="toggleDropdown"
      variant="dots"
      class="dropdown-button"
      :style="iconStyle"
    />
    <div v-if="isOpen" class="dropdown-menu">
      <div
        v-for="(option, index) in options"
        :key="option"
        @click="selectOption(option, index)"
        class="dropdown-menu__item"
      >
        {{ $t(`dropdown.${option}`) }}
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from "./IconButton.vue";

export default {
  name: "DropdownComponent",
  components: { IconButton },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedOption: null,
    };
  },
  computed: {
    iconStyle() {
      return this.$themeStore.isDarkTheme ? { filter: 'invert(1)' } : {};
    },
  },
  methods: {
    toggleDropdown(e) {
      e.stopPropagation();
      this.isOpen = !this.isOpen;
    },
    selectOption(option, index) {
      this.selectedOption = option;
      this.isOpen = false;
      this.$emit("select-option", [option, index]);
    },
    handleClickOutside(event) {
      if (this.$el.contains(event.target)) return;
      this.isOpen = false;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: inline-block;
  &-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--background);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    &__item {
      padding: 8px 16px;
      cursor: pointer;
      transition: background 0.2s;
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:nth-last-child(1) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &:hover {
        background: var(--background-white-hover);
      }
    }
  }
}
</style>
