<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window" v-show="componentIsReady">
      <div class="ui-modal__heading">
        <h3>{{ $t("edit_event_modal.edit_event") }}</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form
        class="ui-modal__form"
        autocomplete="off"
        @submit.prevent="updateEvent"
      >
        <div class="ui-form-group">
          <label class="ui-label">{{ $t("edit_event_modal.date") }}</label>
          <vue-date-picker
            locale="ru"
            v-model="event.createdAt"
            :dark="$themeStore.isDarkTheme"
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">{{
            $t("edit_event_modal.event_name")
          }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('edit_event_modal.event_name')"
            v-model="event.name"
          />
        </div>
        <div
          class="ui-form-group"
          v-if="managerList.length && $userStore.roleId === 1"
        >
          <label class="ui-label">{{ $t("edit_event_modal.manager") }}</label>
          <multi-select-wr
            v-model="multiselect.managerObject"
            :tag-placeholder="$t('edit_event_modal.select_manager')"
            :placeholder="$t('edit_event_modal.start_typing_manager_name')"
            label="name"
            track-by="id"
            multiple
            :options="multiselect.managers"
            @select="selectManager"
            @remove="removeManager"
            required
          />
        </div>

        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">{{ $t("edit_event_modal.viewers") }}</label>
          <multi-select-wr
            v-model="multiselect.canView"
            :tag-placeholder="$t('edit_event_modal.who_can_view_the_event')"
            :placeholder="$t('edit_event_modal.start_typing_employee_name')"
            label="name"
            track-by="id"
            :options="multiselect.managers"
            multiple
            @select="changeCanView"
            @remove="changeCanView"
            required
          />
        </div>

        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">{{ $t("edit_event_modal.editors") }}</label>
          <multi-select-wr
            v-model="multiselect.canEdit"
            :tag-placeholder="$t('edit_event_modal.who_can_edit_an_event')"
            :placeholder="$t('edit_event_modal.start_typing_employee_name')"
            label="name"
            track-by="id"
            multiple
            :options="multiselect.managers"
            @select="changeCanEdit"
            @remove="changeCanEdit"
            required
          />
        </div>

        <div class="ui-form-group" v-if="eventStatuses.length">
          <label class="ui-label">{{
            $t("edit_event_modal.event_status")
          }}</label>
          <multi-select-wr
            v-model="multiselect.eventStatusObject"
            :placeholder="$t('edit_event_modal.select_status')"
            label="name"
            track-by="id"
            :options="multiselect.eventStatuses"
            @select="selectEventStatus"
            @remove="removeEventStatus"
            required
          />
        </div>
        <div class="ui-form-group" v-if="clientList.length">
          <label class="ui-label">{{ $t("edit_event_modal.customer") }}</label>
          <multi-select-wr
            v-model="multiselect.clientObject"
            :tag-placeholder="$t('edit_event_modal.select_customer')"
            :placeholder="$t('edit_event_modal.start_typing_the_customer_name')"
            label="company"
            track-by="id"
            :options="multiselect.clients"
            @select="selectClient"
            @remove="removeClient"
            required
          />
        </div>
        <button
          type="submit"
          class="ui-button"
          v-text="$t('edit_event_modal.save')"
        />
      </form>
    </div>
  </div>
</template>

<script>
import MultiSelectWr from "@/global-components/MultiSelectWr.vue";

export default {
  components: { MultiSelectWr },
  props: ["eventId"],
  // components: {Datepicker},
  data() {
    return {
      multiselect: {
        clientObject: null,
        clients: [],
        managerObject: null,
        managers: [],
        eventStatusObject: null,
        eventStatuses: [],
        canView: [],
        canEdit: [],
      },
      event: {
        id: null,
        name: null,
        managerId: null,
        eventStatusId: null,
        clientId: null,
        createdAt: null,
        canView: null,
        canEdit: null,
      },
      activeFields: {
        email: true,
        password: true,
        name: true,
        phone: true,
        address: true,
        company: true,
        description: true,
      },
      eventStatuses: [],
      clientList: [],
      managerList: [],
      isLoading: false,
      componentIsReady: false,
    };
  },
  methods: {
    changeCanView() {
      this.event.canView = this.multiselect.canView.map((a) => a.id);
    },
    applyCanView() {
      this.multiselect.canView = [];

      if (!this.event.canView) {
        this.event.canView = [];
        return;
      }

      this.multiselect.managers.forEach((manager) => {
        if (this.event.canView.indexOf(manager.id) > -1) {
          this.multiselect.canView.push(manager);
        }
      });
    },
    changeCanEdit() {
      this.event.canEdit = this.multiselect.canEdit.map((a) => a.id);
    },
    applyCanEdit() {
      this.multiselect.canEdit = [];

      if (!this.event.canEdit) {
        this.event.canEdit = [];
        return;
      }

      this.multiselect.managers.forEach((manager) => {
        if (this.event.canEdit.indexOf(manager.id) > -1) {
          this.multiselect.canEdit.push(manager);
        }
      });
    },
    async getEventStatuses() {
      try {
        const { data } = await this.$api.get("/v1/event-status/list");
        this.eventStatuses = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("edit_event_modal.errors.error_while_getting_data")
        );
      }
    },
    async getManagers() {
      try {
        const { data } = await this.$api.get("/v1/staff/list");
        this.managerList = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("edit_event_modal.errors.failed_get_manager_information")
        );
      }
    },
    async getClients() {
      try {
        const { data } = await this.$api.get("/v1/client/list");
        this.clientList = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("edit_event_modal.errors.failed_get_customer_information")
        );
      }
    },
    updateEvent() {
      if (this.isLoading) return false;
      this.isLoading = true;

      this.$api
        .post(`/v1/events/update/${this.event.id}`, this.event)
        .then(() => {
          this.$toast(
            "success",
            this.$t("edit_event_modal.statuses.data_updated")
          );
          this.$emit("success");
        })
        .catch((e) => {
          this.$catch(
            e,
            this.$t("edit_event_modal.errors.error_updating_data")
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getEvent() {
      try {
        const { data } = await this.$api.get(
          `/v1/events/get/${this.$props.eventId}`
        );

        this.event.id = data.id;
        this.event.name = data.name;
        this.event.managerId = data.manager.id;
        this.event.eventStatusId = data.status.id;
        this.event.clientId = data.client.id;
        this.event.canView = data.canView;
        this.event.canEdit = data.canEdit;
        this.event.createdAt = data.createdAt;
      } catch (e) {
        this.$catch(
          e,
          this.$t("edit_event_modal.errors.failed_get_event_data")
        );
      }
    },
    selectClient(client) {
      this.event.clientId = client.id;
    },
    removeClient() {
      this.event.clientId = null;
    },
    selectManager(manager) {
      this.event.managerId = manager.id;
    },
    removeManager() {
      this.event.managerId = null;
    },
    selectEventStatus(status) {
      this.event.eventStatusId = status.id;
    },
    removeEventStatus() {
      this.event.eventStatusId = null;
    }
  },
  async mounted() {
    await Promise.all([
      this.getEventStatuses(),
      this.getManagers(),
      this.getClients(),
      this.getEvent(),
    ])
      .then(() => {
        // Multiselect :: map clients
        this.multiselect.clients = this.clientList.map((client) => {
          return {
            id: client.id,
            company: client.contact.company,
          };
        });

        this.multiselect.clientObject = this.multiselect.clients.filter(
          (client) => {
            if (client.id === this.event.clientId) return client;
          }
        );

        if (this.multiselect.clientObject.length)
          this.multiselect.clientObject = this.multiselect.clientObject[0];

        // Multiselect :: map managers
        this.multiselect.managers = this.managerList.map((manager) => {
          return {
            id: manager.id,
            name: manager.contact.name,
          };
        });

        this.multiselect.managerObject = this.multiselect.managers.filter(
          (manager) => {
            if (manager.id === this.event.managerId) return manager;
          }
        );

        if (this.multiselect.managerObject.length)
          this.multiselect.managerObject = this.multiselect.managerObject[0];

        // Multiselect :: event statuses
        this.multiselect.eventStatuses = this.eventStatuses.map((status) => {
          return {
            id: status.id,
            name: status.name,
          };
        });

        this.multiselect.eventStatusObject =
          this.multiselect.eventStatuses.filter((status) => {
            if (status.id === this.event.eventStatusId) return status;
          });

        if (this.multiselect.eventStatusObject.length)
          this.multiselect.eventStatusObject =
            this.multiselect.eventStatusObject[0];

        this.applyCanEdit();
        this.applyCanView();
      })
      .then(() => {
        this.componentIsReady = true;
      });
  },
};
</script>
