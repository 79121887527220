<template>
  <div class="db-main-view__table-container" v-if="credits?.length || debits?.length">
    <div class="db-main-view__table-filters">
      <div>
        <label class="ui-label">{{ $t('single_event_operations.filter_by_dates') }}</label>
        <vue-date-picker
          locale="ru"
          @update:model-value="applyDateRange"
          range
          :multi-calendars="{ solo: true }"
          v-model="filters.dates"
          :dark="$themeStore.isDarkTheme"
        />
      </div>
      <div>
        <label class="ui-label">{{ $t('single_event_operations.search') }}</label>
        <input
          v-model="searchText"
          @keyup="filterData"
          type="text"
          :placeholder="$t('single_event_operations.search_placeholder')"
          class="ui-input"
        />
      </div>
    </div>

    <div class="db-main-view__table db-main-view__table-heading">
      <div class="db-main-view__table-details">
        <span class="db-main-view__table-date" v-text="$t('single_event_operations.date')" />
        <span class="db-main-view__table-name" v-text="$t('single_event_operations.operation')" />
        <span class="db-main-view__table-receipt" v-text="$t('single_event_operations.documents')" />
        <span class="db-main-view__table-sum" v-text="$t('single_event_operations.coming')" />
        <span class="db-main-view__table-sum" v-text="$t('single_event_operations.consumption')" />
        <span class="db-main-view__table-date" v-text="$t('single_event_operations.status')" />
        <div class="db-main-view__table-buttons" />
      </div>
    </div>

    <div class="db-main-view__table"
         v-for="item in dataFiltred"
         :key="item.type + '_' + item.data.id"
    >
      <div class="db-main-view__table-details" v-if="item.type === 'credit'">
        <span class="db-main-view__table-date">
          {{ $convertDate(item.data.createdAt) }}
        </span>
        <span class="db-main-view__table-name" v-text="item.data.name" />
        <span class="db-main-view__table-receipt">
          <a
            :href="`${$backendUrl}/${item.data.attachment.file}`"
            v-if="item.data.attachment"
            target="blank"
            download
            v-text="$t('single_event_operations.download_document')"
          />
        </span>
        <span class="db-main-view__table-sum">
          &nbsp;
        </span>
        <span class="db-main-view__table-sum">
          {{ "-" + $convertToCurrency(item.data.sum) }}
        </span>
        <span
          :class="{
            'db-main-view__table-date': true,
            red: item.data.status.id === 1,
            green: item.data.status.id === 2
          }"
          v-text="$t(item.data.status.name)"
        />
        <div class="db-main-view__table-buttons" v-if="item.type === 'credit'" v-show="item.data.editable">
          <div class="db-main-view__table-delete" @click="deleteCredit(item.data.id)" />
          <div
            class="db-main-view__table-edit"
            @click="openEditCreditModal(item.data.id)"
          />
        </div>
      </div>

      <div class="db-main-view__table-details" v-if="item.type === 'debit'">
        <span class="db-main-view__table-date">
          {{ $convertDate(item.data.createdAt) }}
        </span>
        <span class="db-main-view__table-name">
          {{ item.data.name }}
        </span>
        <span class="db-main-view__table-receipt"> &nbsp; </span>
        <span class="db-main-view__table-sum">
          {{ $convertToCurrency(item.data.sum) }}
        </span>
        <span class="db-main-view__table-sum">
          &nbsp;
        </span>
        <span
          :class="{
            'db-main-view__table-date': true,
            red: item.data.status.id === 1,
            green: item.data.status.id === 2
          }"
          v-text="$t(item.data.status.name)"
        />
        <div class="db-main-view__table-buttons" v-if="item.type === 'debit'" v-show="item.data.editable">
          <div
            class="db-main-view__table-delete"
            @click.stop="deleteDebit(item.data.id)"
          />
          <div
            class="db-main-view__table-edit"
            @click.stop="openEditDebitModal(item.data.id)"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="$checkPermissions([1, 2])"
    class="db-main-view__item-actions"
  >
    <button
      class="ui-button db-main-view__item-add-credit"
      @click.prevent="openNewCreditModal()"
      v-text="$t('single_event_operations.add_expense')"
    />
    <button
      class="ui-button db-main-view__item-add-credit"
      @click.prevent="openNewDebitModal()"
      v-text="$t('single_event_operations.add_receipt')"
    />
  </div>

  <CreateCreditModal
    v-if="newCredit.isOpen"
    :eventId="newCredit.eventId"
    @closeModal="closeNewCreditModal"
    @success="successNewCreditModal"
  />
  <EditCreditModal
    v-if="editableCredit.isOpen"
    :creditId="editableCredit.id"
    @closeModal="closeEditCreditModal"
    @success="successEditCreditModal"
  />
  <CreateDebitModal
    @closeModal="closeNewDebitModal"
    @success="successNewDebitModal"
    :eventId="newDebit.eventId"
    v-if="newDebit.isOpen"
  />
  <EditDebitModal
    @closeModal="closeEditDebitModal"
    @success="successEditDebitModal"
    :debitId="editDebit.id"
    v-if="editDebit.isOpen"
  />

  <!-- confirmation dialog -->
  <ConfirmationDialog ref="confirmationDialog" />
</template>

<script>
import moment from 'moment';
import ConfirmationDialog from '../../../components/confirmation/ConfirmationDialog.vue';
import CreateCreditModal from "../create-credit/CreateCreditModal.vue";
import CreateDebitModal from "../create-debit/CreateDebitModal.vue";
import EditCreditModal from "../edit-credit/EditCreditModal.vue";
import EditDebitModal from "../edit-debit/EditDebitModal.vue";

export default {
  props: ['eventId'],
  emits: ['updateEventFromChild'],
  components: {
    EditCreditModal,
    CreateCreditModal,
    ConfirmationDialog,
    CreateDebitModal,
    EditDebitModal,
  },
  data() {
    return {
      // credits
      credits: [],
      lastCredits: [],
      allowLastCredits: false,
      displayAllCredits: false,

      // Modals
      editableCredit: {
        id: null,
        isOpen: false,
      },
      newCredit: {
        eventId: this.$props.eventId,
        isOpen: false,
      },

      // debits
      debits: [],
      lastDebits: [],
      allowLastDebits: false,
      displayAllDebits: false,

      // Modals
      newDebit: {
        eventId: null,
        isOpen: false,
      },
      editDebit: {
        id: null,
        isOpen: false,
      },

      // all data
      data: [],
      dataFiltred: [],

      // filters
      searchText: '',
      filters: {
        dates: 0
      }
    }
  },
  methods: {
    // credits
    async getCredits() {
      try {
        const { data } = await this.$api(`/v2/credit/get-by-event/${this.$props.eventId}`)
        this.credits = data
      } catch (e) {
        this.$catch(e)
      }
    },
    // processCredits() {
    // if (this.credits.length < 5) return;
    // this.allowLastCredits = true
    // this.lastCredits = this.credits.slice(-5)
    // },
    openEditCreditModal(id) {
      this.editableCredit.id = id
      this.editableCredit.isOpen = true
    },
    closeEditCreditModal() {
      this.editableCredit.id = null
      this.editableCredit.isOpen = false
    },
    async successEditCreditModal() {
      await this.$emit('updateEventFromChild')
      await this.processEventData()
      this.closeEditCreditModal()
    },
    openNewCreditModal() {
      this.newCredit.isOpen = true
    },
    closeNewCreditModal() {
      this.newCredit.isOpen = false
    },
    async successNewCreditModal() {
      await this.$emit('updateEventFromChild')
      await this.processEventData()
      this.closeNewCreditModal()
    },
    async deleteCredit(id) {
      // confirmation dialog
      const ok = await this.$refs.confirmationDialog.show({
        title: this.$t('single_event_operations.messages.confirm_deletion'),
        content: this.$t('single_event_operations.messages.are_you_sure_want_delete_expense')
      })

      if (!ok) return;

      try {
        await this.$api.delete(`/v2/credit/delete/${id}`);
        this.$notificationsStore.statistics = true;
        await this.$emit('updateEventFromChild')
        await this.processEventData()
      } catch (e) {
        this.$toast("error", this.$t('single_event_operations.errors.insufficient_rights_delete_object'));
      }
    },

    // debits
    async getDebits() {
      try {
        const { data } = await this.$api.get(`/v2/debit/get-by-event/${this.$props.eventId}`)
        this.debits = data
      } catch (e) {
        this.$catch(e, this.$t('single_event_operations.errors.failed_receive_receipts'))
      }
    },
    // processDebits() {
    //   if (this.debits.length > 5) {
    //     this.lastDebits = this.debits.slice(-5)
    //     this.allowLastDebits = true
    //   }
    // },
    async deleteDebit(id) {
      // confirmation dialog
      const ok = await this.$refs.confirmationDialog.show({
        title: this.$t('single_event_operations.messages.confirm_deletion'),
        content: this.$t('single_event_operations.messages.are_you_sure_want_delete_event')
      })

      if (!ok) return;

      try {
        await this.$api.delete(`/v1/debit/delete/${id}`);
        this.$notificationsStore.statistics = true;
        await this.$emit('updateEventFromChild')
        await this.processEventData()
      } catch (e) {
        this.$toast("error", this.$t('single_event_operations.errors.insufficient_rights_delete_object'));
      }
    },
    openEditDebitModal(id) {
      this.editDebit.id = id
      this.editDebit.isOpen = true
    },
    closeEditDebitModal() {
      this.editDebit.id = null
      this.editDebit.isOpen = false
    },
    async successEditDebitModal() {
      await this.$emit('updateEventFromChild')
      await this.processEventData()
      this.closeEditDebitModal()
      
    },
    // new debit
    openNewDebitModal() {
      this.newDebit.eventId = this.$props.eventId
      this.newDebit.isOpen = true
    },
    closeNewDebitModal() {
      this.newDebit.eventId = null
      this.newDebit.isOpen = false
    },
    async successNewDebitModal() {
      await this.$emit('updateEventFromChild')
      await this.processEventData()
      this.closeNewDebitModal()
    },

    // make data
    makeData() {
      this.credits.forEach(credit => {
        this.data.push({
          type: 'credit',
          data: credit
        })
      })

      this.debits.forEach(debit => {
        this.data.push({
          type: 'debit',
          data: debit
        })
      })


      this.data.sort(
        (a, b) => {
          const dateA = +new Date(moment(a.data.createdAt).format()).getTime() / 1000
          const dateB = +new Date(moment(b.data.createdAt).format()).getTime() / 1000

          if(parseInt(dateA) > parseInt(dateB)) {
              return 1
          }

          if(parseInt(dateA) < parseInt(dateB)) {
              return -1
          }

          return 0
        }
      )

      console.log('this.data', this.data)

      this.dataFiltred = this.data
    },

    // filter data
    filterData() {
      if(this.searchText.length) {
        this.dataFiltred = this.data.filter(item =>
          item.data.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 ||
          String(item.data.sum).toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
        )
      } else {
        this.dataFiltred = this.data
      }

      if (!this.filters.dates) return
      this.dataFiltred = 
        this.dataFiltred.filter(
          e => new Date(e.data.createdAt).getTime() >= new Date(this.filters.dates[0]).getTime() && 
          new Date(e.data.createdAt).getTime() <= new Date(this.filters.dates[1]).getTime()
        )
    },
    applyDateRange(d) {
      if (!d) {
        this.filters.dates = 0
      } else {
        this.filters.dates = [d[0], d[1]]
      }
      
      this.filterData()
    },
    async processEventData() {
      this.data = []
      this.dataFiltred = []

      await Promise.all([
        this.getCredits(),
        this.getDebits()
      ])
      this.makeData()
      this.filterData()
    }
  },
  async mounted() {
    await this.processEventData()
  }
}
</script>
