<template>
  <div class="checkbox-wrapper-12">
    <div class="cbx">
      <input id="cbx-12" type="checkbox" />
      <input
        class="cbx-12"
        type="checkbox"
        :checked="isDone"
        @change="updateStatus"
      />
      <label for="cbx-12" />
      <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
        <path d="M2 8.36364L6.23077 12L13 2" />
      </svg>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="goo-12">
          <fegaussianblur in="SourceGraphic" stddeviation="4" result="blur" />
          <fecolormatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0 0 1 0 0 0 0 0
          1 0 0 0 0 0 22 -7"
            result="goo-12"
          />
          <feblend in="SourceGraphic" in2="goo-12"></feblend>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    isDone: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  methods: {
    updateStatus(event) {
      const newStatus = event.target.checked;
      this.$emit("status-updated", newStatus);
    },
  },
};
</script>

<style scoped lang="scss" src="./TaskStatusButton.scss" />
