<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window" v-if="componentIsReady">
      <div class="ui-modal__heading">
        <h3>{{ $t("create_event_modal.create_an_events") }}</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form
        class="ui-modal__form"
        autocomplete="off"
        @submit.prevent="createEvent"
      >
        <div class="ui-form-group">
          <label class="ui-label">{{ $t("create_event_modal.date") }}</label>
          <vue-date-picker
            locale="ru"
            v-model="eventData.event.createdAt"
            required
            :dark="$themeStore.isDarkTheme"
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">{{
            $t("create_event_modal.event_name")
          }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('create_event_modal.event_name')"
            v-model="eventData.event.name"
            required
          />
        </div>

        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">{{ $t("create_event_modal.manager") }}</label>
          <multi-select-wr
            v-model="multiselect.managerObject"
            :tag-placeholder="$t('create_event_modal.select_manager')"
            :placeholder="$t('create_event_modal.start_typing_managers_name')"
            label="name"
            track-by="id"
            multiple
            :options="multiselect.managers"
            @select="selectManager"
            @remove="removeManager"
            required
          />

          <template v-if="isVisibleAddNewManager">
            <div class="db-main-view__buttons-group">
              <div>
                <input
                  type="text"
                  class="ui-input"
                  :placeholder="$t('create_event_modal.input_email')"
                  v-model="searchUserEmail"
                />
              </div>
              <button
                class="ui-button db-main-view__filters-btn"
                :hidden="isVisibleLoaderSearch"
                @click.prevent="searchManager"
              >
                <span v-if="isVisibleLoaderSearch">{{ $t("create_event_modal.loading") }}</span>
                <span v-else>{{ $t("create_event_modal.search") }}</span>
              </button>
            </div>
          </template>
        </div>

        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">{{
            $t("create_event_modal.observers")
          }}</label>
          <multi-select-wr
            v-model="multiselect.canView"
            :tag-placeholder="$t('create_event_modal.who_can_view_the_event')"
            :placeholder="$t('create_event_modal.start_typing_employee_name')"
            label="name"
            track-by="id"
            multiple
            :options="multiselect.managers"
            @select="changeCanView"
            @remove="changeCanView"
            required
          />
        </div>
        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">{{ $t("create_event_modal.editors") }}</label>
          <multi-select-wr
            v-model="multiselect.canEdit"
            :tag-placeholder="$t('create_event_modal.who_can_edit_an_event')"
            :placeholder="$t('create_event_modal.start_typing_employee_name')"
            label="name"
            track-by="id"
            multiple
            :options="multiselect.managers"
            @select="changeCanEdit"
            @remove="changeCanEdit"
            required
          />
        </div>

        <div class="ui-form-group" v-if="multiselect.eventStatuses.length">
          <label class="ui-label">{{
            $t("create_event_modal.event_status")
          }}</label>
          <multi-select-wr
            v-model="multiselect.eventStatusObject"
            :placeholder="$t('create_event_modal.select_status')"
            label="name"
            track-by="id"
            :options="multiselect.eventStatuses"
            @select="selectEventStatus"
            @remove="removeEventStatus"
            required
          />
        </div>

        <div class="ui-form-group">
          <label class="ui-label">{{ $t("create_event_modal.client") }}</label>
          <multi-select-wr
            v-model="multiselect.clientObject"
            :tag-placeholder="$t('create_event_modal.select_client')"
            :placeholder="$t('create_event_modal.start_typing_customer_name')"
            label="company"
            track-by="id"
            :options="multiselect.clients"
            @select="selectClient"
            @remove="removeClient"
            required
          />
        </div>
        <template v-if="isVisibleAddNewUser">
          <div class="db-main-view__buttons-group">
            <div>
              <input
                type="text"
                class="ui-input"
                :placeholder="$t('create_event_modal.input_email')"
                v-model="searchUserEmail"
              />
            </div>
            <button
              class="ui-button db-main-view__filters-btn"
              :hidden="isVisibleLoaderSearch"
              @click.prevent="searchUser"
            >
              <span v-if="isVisibleLoaderSearch">{{ $t("create_event_modal.loading") }}</span>
              <span v-else>{{ $t("create_event_modal.search") }}</span>
            </button>
          </div>
        </template>
        <h3 v-if="eventData.event.clientId === 0">
          {{ $t("create_event_modal.new_client") }}
        </h3>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">{{ $t("create_event_modal.email") }}</label>
          <input
            type="email"
            class="ui-input"
            :placeholder="$t('create_event_modal.email')"
            v-model="eventData.client.email"
            autocomplete="off"
            required
            :readonly="activeFields.email"
            @focusin="activeFields.email = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">{{
            $t("create_event_modal.password")
          }}</label>
          <input
            type="password"
            class="ui-input"
            :placeholder="$t('create_event_modal.password')"
            v-model="eventData.client.password"
            autocomplete="off"
            required
            :readonly="activeFields.password"
            @focus="activeFields.password = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">{{
            $t("create_event_modal.client_name")
          }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('create_event_modal.client_name')"
            v-model="eventData.client.name"
            autocomplete="off"
            required
            :readonly="activeFields.name"
            @focus="activeFields.name = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">{{ $t("create_event_modal.phone") }}</label>
          <phone-input
            :placeholder="$t('create_event_modal.phone')"
            :value="eventData.client.phone"
            :readonly="activeFields.phone"
            @input="inputPhone"
            @focus="activeFields.phone = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">{{ $t("create_event_modal.address") }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('create_event_modal.address')"
            v-model="eventData.client.address"
            autocomplete="off"
            required
            :readonly="activeFields.address"
            @focus="activeFields.address = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">{{ $t("create_event_modal.company") }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('create_event_modal.company')"
            v-model="eventData.client.company"
            autocomplete="off"
            required
            :readonly="activeFields.company"
            @focus="activeFields.company = false"
          />
        </div>
        <button
          type="submit"
          class="ui-button"
          v-text="$t('create_event_modal.save')"
        />
      </form>
    </div>
  </div>
</template>

<script>
import MultiSelectWr from "@/global-components/MultiSelectWr.vue";
import PhoneInput from "@/global-components/PhoneInput.vue";

export default {
  components: {
    MultiSelectWr,
    PhoneInput
  },
  props: ["eventId"],
  data() {
    return {
      multiselect: {
        clients: [],
        clientObject: null,
        managers: [],
        managerObject: null,
        eventStatuses: [],
        eventStatusObject: null,
        canView: [],
        canEdit: [],
      },
      eventData: {
        event: {
          name: null,
          managerId: null,
          eventStatusId: null,
          clientId: null,
          createdAt: Date.now(),
          canView: [],
          canEdit: [],
        },
        client: {
          email: null,
          password: null,
          name: null,
          phone: null,
          address: null,
        },
      },
      activeFields: {
        email: true,
        password: true,
        name: true,
        phone: true,
        address: true,
        company: true,
        description: true,
      },
      eventStatuses: [],
      clientList: [],
      managerList: [],
      isLoading: false,
      componentIsReady: false,
      isVisibleLoaderSearch: false,
      searchUserEmail: '',
      isVisibleAddNewManager: false,
      isVisibleAddNewUser: false
    };
  },
  async mounted() {
    await Promise.all([
      this.getEventStatuses(),
      this.getManagers(),
      this.getClients(),
    ])
      .then(() => {
        // MultiSelect :: map clients
        this.multiselect.clients.push(
          {
            id: -1,
            company: this.$t("create_event_modal.add_already_created_client"),
          },
        );

        this.multiselect.clients = this.multiselect?.clients?.concat(
          this.clientList.map((client) => {
            return {
              id: client.id,
              company: client.contact.company,
            };
          })
        );

        // MultiSelect :: map managers
        this.multiselect.managers.push(
          {
            id: -1,
            name: this.$t("create_event_modal.add_already_created_manager"),
          },
          ...this.managerList.map((manager) => {
            return {
              id: manager.id,
              name: manager.contact.name,
            };
          }),
        )

        // Multiselect :: map event statuses
        this.multiselect.eventStatuses = this.eventStatuses.map((status) => {
          return {
            id: status.id,
            name: status.name,
          };
        });
      })
      .then(() => {
        this.componentIsReady = true;
      });
  },
  methods: {
    inputPhone(e) {
      this.eventData.client.phone = e.target.value
    },
    selectClient(client) {
      this.isVisibleAddNewUser = client.id === -1;
      this.eventData.event.clientId = client.id;
    },
    removeClient() {
      this.eventData.event.clientId = null;
    },
    selectManager(manager) {
      this.isVisibleAddNewManager = manager.id === -1;
      this.eventData.event.managerId = manager.id;
    },
    removeManager() {
      this.eventData.event.managerId = null;
    },
    selectEventStatus(status) {
      this.eventData.event.eventStatusId = status.id;
    },
    removeEventStatus() {
      this.eventData.event.eventStatusId = null;
    },
    async getEventStatuses() {
      try {
        const { data } = await this.$api.get("/v1/event-status/list");
        this.eventStatuses = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("create_event_modal.errors.error_while_receiving_data")
        );
      }
    },
    async getManagers() {
      try {
        const { data } = await this.$api.get("/v1/staff/list");
        this.managerList = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t(
            "create_event_modal.errors.failed_get_information_about_managers"
          )
        );
      }
    },
    async getClients() {
      try {
        const { data } = await this.$api.get("/v1/client/list");
        this.clientList = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t(
            "create_event_modal.errors.failed_retrieve_customer_information"
          )
        );
      }
    },
    createEvent() {
      if (this.isLoading) return false;
      this.isLoading = true;
      console.log(this.eventData)

      this.$api
        .post(`/v1/events/create`, this.eventData)
        .then(() => {
          this.$toast(
            "success",
            this.$t("create_event_modal.statuses.deal_added")
          );
          this.$emit("success");
        })
        .catch((e) => {
          console.log('catch e', e);
          if (e.response?.data?.message) this.$toast("error", e.response.data.message);
          else this.$catch(
            e,
            this.$t("create_event_modal.errors.data_update_error")
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeCanView(user) {
      this.eventData.event.canView = this.eventData.event.canView.concat(user.id)
    },
    changeCanEdit(user) {
      this.eventData.event.canEdit = this.eventData.event.canEdit.concat(user.id)
    },
    async searchUser () {
      this.isVisibleLoaderSearch = true

      try {
        const { data } = await this.$api.get(`/v1/client/search?email=${this.searchUserEmail}`);

        if (data) {
          const result = {
            id: data.id,
            company: data.contact.company
          }

          this.multiselect.clients.push(result)
          this.multiselect.clientObject = result
          this.isVisibleAddNewUser = false
          this.eventData.event.clientId = result.id

          this.$toast('success', this.$t('create_event_modal.statuses.user_found'))
        } else {
          this.$toast('error', this.$t('create_event_modal.statuses.user_notfound'))
        }
      } catch (e) {
        console.error(e)
      }

      this.isVisibleLoaderSearch = false
      this.searchUserEmail = null
    },
    async searchManager () {
      this.isVisibleLoaderSearch = true

      try {
        const { data } = await this.$api.get(`/v1/client/search-manager?email=${this.searchUserEmail}`);

        if (data) {
          const result = {
            id: data.id,
            name: data.contact.name
          }
          this.multiselect.managerObject = [...this.multiselect.managerObject.filter((m) => m.id !== -1), result]
          this.isVisibleAddNewManager = false
          this.multiselect.managers.push(result)
          this.eventData.event.managerId = result.id

          this.$toast('success', this.$t('create_event_modal.statuses.user_found'))
        } else {
          this.$toast('error', this.$t('create_event_modal.statuses.user_notfound'))
        }
      } catch (e) {
        console.error(e)
      }

      this.isVisibleLoaderSearch = false
      this.searchUserEmail = null
    }
  }
};
</script>
