<template>
  <div class="mobile-view">
    <h1>{{ $t("mobile.title") }}</h1>
  </div>
</template>

<script>
export default {
  name: "MobileView",
};
</script>

<style scoped>
.mobile-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #333;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 1.5em;
  padding: 20px;
}
</style>
