<template>
  <div class="tariff-block__grid-1 mb-15">
    <div class="widget">
      <h3 class="pointer" @click="toggleVisibleForm">{{ $t('settings.addition_user.addition') }}</h3>

      <form
        v-if="isVisibleForm"
        class="personal-account ui-modal__form"
        @submit.prevent="save"
      >
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="role"
          >{{ $t('settings.addition_user.role') }}</label>
          <select
            v-model="mainData.roleId"
            class="ui-input"
            id="role"
            required
          >
            <option
              v-for="role in rolesList"
              :key="role.id"
              :value="role.id"
            >{{ role.name }}</option>
          </select>
        </div>

        <div
          v-if="mainData.roleId === 1"
          class="ui-form-group"
        >
          <label
            class="ui-label"
            for="organization_name"
          >{{ $t('settings.addition_user.organization_name') }}</label>
          <input
            id="organization_name"
            type="text"
            class="ui-input"
            :placeholder="$t('settings.addition_user.organization_name')"
            v-model="mainData.organizationName"
            required
          />
        </div>

        <div class="ui-form-group">
          <label
            class="ui-label"
            for="email"
          >{{ $t('settings.addition_user.email') }}</label>
          <input
            id="email"
            type="email"
            class="ui-input"
            :placeholder="$t('settings.addition_user.email')"
            v-model="mainData.email"
            required
          />
        </div>
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="password"
          >{{ $t('settings.addition_user.password') }}</label>
          <input
            id="password"
            type="text"
            class="ui-input-no-hover"
            :placeholder="$t('settings.addition_user.password')"
            v-model="mainData.password"
            required
          />
        </div>

        <div
          v-if="mainData.roleId !== 1"
          class="ui-form-group"
        >
          <label
            class="ui-label"
            for="organization"
          >{{ $t('settings.addition_user.organization') }}</label>
          <select
            v-model="mainData.organizationId"
            class="ui-input"
            id="organization"
            required
          >
            <option
              v-for="organization in organizationsList"
              :key="organization.organizationId"
              :value="organization.organizationId"
            >{{ organization.email }}</option>
          </select>
        </div>

        <div class="ui-form-group">
          <label
            class="ui-label"
            for="name"
          >{{ $t('settings.addition_user.name') }}</label>
          <input
            id="name"
            type="text"
            class="ui-input-no-hover"
            :placeholder="$t('settings.addition_user.name')"
            v-model="contacts.name"
            required
          />
        </div>

        <div class="ui-form-group">
          <label
            class="ui-label"
            for="phone_number"
          >{{ $t('settings.addition_user.phone_number') }}</label>
          <phone-input
            id="phone_number"
            :placeholder="$t('settings.addition_user.phone_number')"
            :value="contacts.phone"
            @input="inputPhone"
          />
        </div>

        <div class="ui-form-group">
          <label
            class="ui-label"
            for="address"
          >{{ $t('settings.addition_user.address') }}</label>
          <input
            id="address"
            type="text"
            class="ui-input-no-hover"
            :placeholder="$t('settings.addition_user.address')"
            v-model="contacts.address"
            required
          />
        </div>

        <button type="submit" class="ui-button" v-text="$t('settings.addition_user.save')" />
      </form>
    </div>
  </div>
</template>

<script>
import PhoneInput from "@/global-components/PhoneInput.vue";

export default {
  components: {PhoneInput},
  data() {
    return {
      mainData: {
        email: null,
        password: null,
        organizationId: null,
        roleId: null,
        organizationName: null
      },
      contacts: {
        name: null,
        phone: null,
        address: null
      },
      organizationsList: [],
      rolesList: [],
      isVisibleForm: false
    }
  },
  created() {
    this.loadAllCompany()
    this.loadAllRoles()
  },
  methods: {
    async toggleVisibleForm () {
      this.isVisibleForm = !this.isVisibleForm
    },
    inputPhone(e) {
      this.contacts.phone = e.target.value
    },
    async loadAllCompany () {
      const { data } = await this.$api.get(`/v1/user/list/organizations`)

      this.organizationsList = data
    },
    async loadAllRoles () {
      const { data } = await this.$api.get('/v1/user-role/list')

      this.rolesList = data.filter(({ name }) => !["Superuser"].includes(name))
    },
    async save() {
      const data = {
        mainData: this.mainData,
        contacts: this.contacts
      }

      await this.$api.post(`/v1/change-db/create/user/${this.$userStore.userId}`, data)

      this.resetData()
    },
    resetData() {
      this.mainData = {
        email: null,
        password: null,
        organizationId: null,
        organizationName: null,
        roleId: null
      }

      this.contacts = {
        name: null,
        phone: null,
        address: null
      }
    }
  }
}
</script>
