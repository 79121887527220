<template>
  <div class="tariff-block__grid-1">
    <div class="widget">
      <h3>{{ $t('personal_account.info.main_info') }}</h3>

      <form class="personal-account ui-modal__form" @submit.prevent="changeData">
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="name"
          >{{ $t('personal_account.form.personal_information.name') }}</label>
          <input
            id="name"
            type="text"
            class="ui-input"
            :placeholder="$t('personal_account.form.personal_information.name')"
            v-model="user.name"
            required
          />
        </div>
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="email"
          >{{ $t('personal_account.form.personal_information.email') }}</label>
          <input
            id="email"
            type="email"
            class="ui-input-no-hover"
            readonly
            :placeholder="$t('personal_account.form.personal_information.email')"
            v-model="user.email"
            required
          />
        </div>
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="phone"
          >{{ $t('personal_account.form.personal_information.phone_number') }}</label>
          <phone-input
            :placeholder="$t('personal_account.form.personal_information.phone_number')"
            :value="user.phone"
            v-model="user.phone"
            id="phone"
            @input="inputPhone"
          />
        </div>
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="address"
          >{{ $t('personal_account.form.personal_information.address') }}</label>
          <input
            id="address"
            type="text"
            class="ui-input"
            :placeholder="$t('personal_account.form.personal_information.address')"
            v-model="user.address"
            required
          />
        </div>
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="company"
          >{{ $t('personal_account.form.personal_information.company_name') }}</label>
          <input
            id="company"
            type="text"
            class="ui-input-no-hover"
            readonly
            :placeholder="$t('personal_account.form.personal_information.company_name')"
            v-model="user.company"
            required
          />
        </div>
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="description"
          >{{ $t('personal_account.form.personal_information.description') }}</label>
          <textarea
            id="description"
            class="ui-textarea"
            :placeholder="$t('personal_account.form.personal_information.description')"
            v-model="user.description"
            required
          />
        </div>

        <button type="submit" class="ui-button" v-text="$t('personal_account.form.personal_information.save')" />
      </form>
    </div>

    <div
      v-if="$userStore.roleId === 1"
      class="widget"
    >
      <h3>{{ $t('personal_account.info.currency') }}</h3>

      <form class="personal-account ui-modal__form" @submit.prevent="changeCurrency">
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="currency"
          >{{ $t('personal_account.form.personal_information.currency') }}</label>
          <select
            v-model="user.currency"
            class="ui-input"
            id="currency"
            required
          >
            <option
              v-for="role in allowedCurrency"
              :key="role.symbol"
              :value="role.symbol"
            >{{ role.name }}</option>
          </select>
        </div>

        <button type="submit" class="ui-button" v-text="$t('personal_account.form.personal_information.save')" />
      </form>
    </div>

    <div class="widget">
      <h3>{{ $t('personal_account.info.change_password') }}</h3>

      <form class="personal-account ui-modal__form" @submit.prevent="changePass">
        <div class="ui-form-group">
          <label
            class="ui-label"
            for="old_password"
          >{{ $t('personal_account.form.personal_information.old_password') }}</label>
          <input
            id="old_password"
            type="password"
            class="ui-input"
            :placeholder="$t('personal_account.form.personal_information.old_password')"
            v-model="user.old_password"
            required
          />
        </div>

        <div class="ui-form-group">
          <label
            class="ui-label"
            for="new_password"
          >{{ $t('personal_account.form.personal_information.new_password') }}</label>
          <input
            id="new_password"
            type="password"
            class="ui-input"
            :placeholder="$t('personal_account.form.personal_information.new_password')"
            v-model="user.new_password"
            required
          />
        </div>

        <button type="submit" class="ui-button" v-text="$t('personal_account.form.personal_information.save')" />
      </form>
    </div>

  </div>
</template>

<script>
import PhoneInput from "@/global-components/PhoneInput.vue";

const ALLOWED_CURRENCY = [
  {
    name: "₽ - RUB",
    symbol: "₽"
  },
  {
    name: "€ - EUR",
    symbol: "€"
  },
  {
    name: "$ - USD",
    symbol: "$"
  },
  {
    name: "¥ - CNY",
    symbol: "¥"
  },
  {
    name: "¥ - JPY",
    symbol: "¥"
  },
  {
    name: "£ - GBP",
    symbol: "£"
  }
]

export default {
  components: {
    PhoneInput
  },
  data() {
    return {
      user: {
        name: null,
        email: null,
        phone: null,
        address: null,
        company: null,
        description: null,
        currency: null,
        old_password: null,
        new_password: null
      },
      allowedCurrency: ALLOWED_CURRENCY,
      isLoading: false
    }
  },
  created () {
    this.getUserData()
  },
  methods: {
    async getUserData () {
      try {
        const { data } = await this.$api.get(`/v1/user/get/${this.$userStore.userId}`)

        this.seedUserData(data)
      } catch(e) {
        this.$catch(e, this.$t('personal_account.form.personal_information.error.get'))
      }
    },
    seedUserData (data) {
      this.user.name = data.contact.name
      this.user.email = data.email
      this.user.phone = data.contact.phone || '+'
      this.user.address = data.contact.address
      this.user.company = data.contact.company
      this.user.description = data.contact.description
      this.user.currency = data.currency
    },
    inputPhone(e) {
      this.user.phone = e.target.value
    },
    changeData () {
      if (this.isLoading) return false
      this.isLoading = true

      this.$api
        .post('/v1/contact/create', {
          name: this.user.name,
          phone: this.user.phone,
          address: this.user.address,
          company: this.user.company,
          description: this.user.description,
          currency: this.user.currency
        })
        .then(() => {
          this.$toast('success', this.$t('personal_account.form.personal_information.statuses.updates_success'))
        })
        .catch(e => {
          this.$catch(e, this.$t('personal_account.form.personal_information.error.change'))
        })
        .finally(() => {
          this.inProgress = false
          this.isLoading = false
        })
    },
    changeCurrency () {
      if (this.isLoading) return false
      this.isLoading = true

      this.$api
        .get(`/v1/user/change-currency/${this.$userStore.userId}/${this.user.currency}`)
        .then(() => {
          this.$toast('success', this.$t('personal_account.form.personal_information.statuses.updates_success'))

          this.$userStore.currency = this.user.currency
        })
        .catch(e => {
          this.$catch(e, this.$t('personal_account.form.personal_information.error.change'))
        })
        .finally(() => {
          this.inProgress = false
          this.isLoading = false
        })
    },
    changePass () {
      if (this.isLoading) return false
      this.isLoading = true

      this.$api
        .post(`/v1/user/change-password/${this.$userStore.userId}`, {
          old_password: this.user.old_password,
          new_password: this.user.new_password
        })
        .then(() => {
          this.$toast('success', this.$t('personal_account.form.personal_information.statuses.updates_success'))

          this.$router.push("/");
        })
        .catch(e => {
          this.$catch(e, this.$t('personal_account.form.personal_information.error.change'))
        })
        .finally(() => {
          this.inProgress = false
          this.isLoading = false
        })
    }
  }
}
</script>
