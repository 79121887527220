export const ICON_BUTTON_CONTENT = new Map([
  [
    "plus",
    {
      icon: "/icons/plus-icon.svg",
      alt: "Добавить",
    },
  ],
  [
    "more",
    {
      icon: "/icons/more-icon.svg",
      alt: "more",
    },
  ],
  [
    "close",
    {
      icon: "/icons/close-icon.svg",
      alt: "close",
    },
  ],
  [
    "dots",
    {
      icon: "/icons/dots-icon.svg",
      alt: "dots",
    },
  ]
]);
