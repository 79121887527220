import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            name: useStorage('name', null),
            email: useStorage('email', null),
            token: useStorage('token', null),
            roleId: useStorage('roleId', null),
            userId: useStorage('userId', null),
            currency: useStorage('currency', null)
        }
    }
})
