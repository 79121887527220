<template>
  <input
    :id="id"
    type="tel"
    class="ui-input"
    :placeholder="placeholder"
    :readonly="readonly"
    :value="inputText"
  />
</template>

<script>
export default {
  name: "PhoneInput",
  data() {
    return {
      inputText: this.value
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    readonly: Boolean,
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue?.length < oldValue?.length && newValue?.length <= 1) {
        this.inputText = newValue

        return
      }

      const arrayValue = newValue?.replace('+', '')?.split('')
      arrayValue?.unshift('+')

      this.inputText = arrayValue?.join('')
    }
  }
};
</script>
