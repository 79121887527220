<template>
  <div class="db-credit-types" v-if="componentIsReady">
    <div class="db-credit-types__buttons">
      <button
        class="ui-button"
        @click="openCreateCreditTypeModal"
        v-text="$t('credit_types_view.add_expense_type')"
      />
    </div>
    <div class="db-credit-types__table">
      <div class="db-credit-types__table-heading">
        <div>{{ $t('credit_types_view.name') }}</div>
      </div>

      <!--items-->
      <div
        class="db-credit-types__table-item"
        v-for="item of creditTypes"
        :key="item.id"
      >
        <a href="#" v-text="item.name" @click.prevent="openEditCreditTypeModal(item.id)" />
      </div>

      <div 
        class="db-credit-types__table-empty"
        v-if="!creditTypes.length"
      >
        {{ $t('credit_types_view.expense_types_found') }}
      </div>
    </div>

    <CreateCreditTypeComponent
      @closeModal="closeCreateCreditTypeModal"
      @successModal="successCreateCreditTypeModal"
      v-if="createCreditType.isVisible"
    />
    <EditCreditTypeComponent
      :creditTypeId="editCreditType.id"
      @closeModal="closeEditCreditTypeModal"
      @successModal="successEditCreditTypeModal"
      v-if="editCreditType.isVisible"
    />
  </div>
</template>

<script>
import CreateCreditTypeComponent from './components/create-credit-type/CreateCreditTypeComponent.vue';
import EditCreditTypeComponent from './components/edit-credit-type/EditCreditTypeComponent.vue';

export default {
  data() {
    return {
      creditTypes: [],
      componentIsReady: false,
      createCreditType: {
        isVisible: false
      },
      editCreditType: {
        id: null,
        isVisible: false
      }
    }
  },
  components: {
    CreateCreditTypeComponent,
    EditCreditTypeComponent
  },
  methods: {
    async getCreditTypes() {
      try {
        const { data } = await this.$api.get('/v1/credit-type/list')
        this.creditTypes = data
      } catch (e) {
        this.$catch(e, this.$t('credit_types_view.errors.getting_expense_types'))
      }
    },
    openEditCreditTypeModal(id) {
      this.editCreditType.id = id
      this.editCreditType.isVisible = true
    },
    async successEditCreditTypeModal() {
      await this.getCreditTypes()
      this.closeEditCreditTypeModal()
    },
    closeEditCreditTypeModal() {
      this.editCreditType.isVisible = false
    },
    openCreateCreditTypeModal() {
      this.createCreditType.isVisible = true
    },
    async successCreateCreditTypeModal() {
      await this.getCreditTypes()
      this.closeCreateCreditTypeModal()
    },
    closeCreateCreditTypeModal() {
      this.createCreditType.isVisible = false
    }
  },
  mounted() {
    Promise
      .all([
        this.getCreditTypes(),
      ])
      .then(() => {
        this.componentIsReady = true
      })
  }
}
</script>

<style src="./style.scss" lang="scss"/>
