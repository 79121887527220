<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window">
      <div class="ui-modal__heading">
        <h3>{{ $t('create_credit_type.adding_a_flow_type') }}</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form class="ui-modal__form" @submit.prevent="createCreditType">
        <div class="ui-form-group">
          <label class="ui-label">{{ $t('create_credit_type.expense_name') }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('create_credit_type.flow_type_name')"
            v-model="creditType.name"
            required
          />
        </div>

        <button type="submit" class="ui-button" v-text="$t('create_credit_type.save')" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      creditType: {
        name: ''
      },
      isLoading: false
    }
  },
  methods: {
    createCreditType() {
      if (this.isLoading) return false
      this.isLoading = true
      this
        .$api
        .post('/v1/credit-type/create', this.creditType)
        .then(() => {
          this.$toast('success', this.$t('create_credit_type.errors.flow_type_added'))
          this.$emit('successModal')
        })
        .catch(e => this.$catch(e, this.$t('create_credit_type.errors.failed_add_expense_type')))
        .finally(() => this.isLoading = false)
    }
  }
}
</script>