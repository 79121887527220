<template>
    <div class="db-users">
        <div class="db-users__buttons">
            <button class="ui-button" @click.prevent="openCreateUserModal">{{ $t('user_list_view.add_user') }}</button>
        </div>

        <div class="db-users__list" v-if="userList.length">
            <div
                class="db-users__item"
                v-for="user in userList"
                :key="user.id"
            >
                <div class="db-users__item-heading">
                    <span class="db-users__item-name">
                        {{ user.contact.name }}
                    </span>
                    <span class="db-users__item-date">
                        {{ $convertDate(user.createdAt) }}
                    </span>
                </div>
                <div class="db-users__item-data">
                    <div class="db-users__item-details">
                        <div class="db-users__item-role" v-if="user.role.name">
                            {{ user.role.name }}
                        </div>
                        <div class="db-users__item-company" v-if="user.contact.company">
                            {{ user.contact.company }}
                        </div>
                        <div class="db-users__item-email">
                            {{ user.email }}
                        </div>
                        <div class="db-users__item-phone" v-if="user.contact.phone">
                            {{ user.contact.phone }}
                        </div>
                    </div>
                    <div class="db-users__item-buttons">
                        <div class="db-users__item-edit" @click.prevent="openEditUserModal(user.id)" />
                    </div>
                </div>
            </div>
        </div>

        <CreateUserModal @closeModal="closeCreateUserModal" @success="successCreateUserModal" v-if="createUser.isOpen" />
        <EditUserModal :userId="editUser.id" @closeModal="closeEditUserModal" @success="successEditUserModal" v-if="editUser.isOpen" />
    </div>
</template>

<script>
import CreateUserModal from './components/create-user/CreateUserModal.vue';
import EditUserModal from './components/edit-user/EditUserModal.vue';

export default {
    components: {CreateUserModal, EditUserModal},
    data() {
        return {
            userList: [],
            createUser: {
                isOpen: false
            },
            editUser: {
                isOpen: false,
                id: null,
                data: null
            }
        }
    },
    methods: {
        async getUserList() {
            try {
                const {data} = await this.$api.get('/v1/user/list')

                // Убрал из общего списка личные данные пользователя
                this.userList = data.filter(({ id }) => String(id) !== String(this.$userStore.userId))
            } catch(e) {
                this.$catch(e, this.$t('user_list_view.errors.failed_get_user_data'))
            }
        },
        openCreateUserModal() {
            this.createUser.isOpen = true
        },
        closeCreateUserModal() {
            this.createUser.isOpen = false
        },
        successCreateUserModal() {
            this.closeCreateUserModal()
            this.getUserList()
        },
        openEditUserModal(id) {
            this.editUser.isOpen = true
            this.editUser.id = id
        },
        closeEditUserModal() {
            this.editUser.isOpen = false
            this.editUser.id = null
        },
        successEditUserModal() {
            this.closeEditUserModal()
            this.getUserList()
        }
    },
    async mounted() {
        await this.getUserList()
    }
}
</script>

<style src="./style.scss" lang="scss"/>
