<template>
  <div
    v-if="Number($userStore.roleId) === 4"
    class="admin-statistics"
  >
    <div class="admin-statistics__main-info mt-15">
      <div class="db-statistics__grid-2">
        <div class="db-statistics__widget db-statistics__credits">
          <h3>{{ $t('admin_statistics.users') }}</h3>
          <table>
            <tr>
              <td>{{ $t('admin_statistics.count_users') }}</td>
              <td>{{ $t('admin_statistics.count_payed') }}</td>
              <td>{{ $t('admin_statistics.count_not_payed') }}</td>
            </tr>
            <tr>
              <td>{{ mainData?.users?.countUsers }}</td>
              <td>{{ mainData?.users?.countPayed }}</td>
              <td>{{ mainData?.users?.countNotPayed }}</td>
            </tr>
          </table>
        </div>

        <div class="db-statistics__widget db-statistics__credits">
          <h3>{{ $t('admin_statistics.tariffs') }}</h3>
          <table>
            <tr>
              <td
                v-for="tariff in Object.keys(mainData?.tariffs)"
                :key="tariff + 'h'"
              >{{ mainData.tariffs[tariff].title }}</td>
            </tr>
            <tr>
              <td
                v-for="tariff in Object.keys(mainData?.tariffs)"
                :key="tariff + 'd'"
              >{{ mainData.tariffs[tariff].count}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="admin-statistics__users_list mt-15">
      <div class="widget">
        <h3 class="pointer" @click="toggleVisibleUsersPayedList">{{ $t('admin_statistics.users_payed_list') }}</h3>

        <table v-if="isVisibleUsersPayedList">
          <tr>
            <td>{{ $t('admin_statistics.id') }}</td>
            <td>{{ $t('admin_statistics.company') }}</td>
            <td>{{ $t('admin_statistics.name') }}</td>
            <td>{{ $t('admin_statistics.email') }}</td>
            <td>{{ $t('admin_statistics.phone') }}</td>
            <td>{{ $t('admin_statistics.event_count') }}</td>
            <td>{{ $t('admin_statistics.address') }}</td>
          </tr>
          <tr
            v-for="user in users.payed"
            :key="user.id"
          >
            <td>{{ user.id }}</td>
            <td>{{ user.contact?.company }}</td>
            <td>{{ user.contact?.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.contact?.phone }}</td>
            <td>{{ user.tariffHistory?.eventsCount }}</td>
            <td>{{ user.contact?.address }}</td>
          </tr>
        </table>
      </div>

      <div class="widget mt-15">
        <h3 class="pointer" @click="toggleVisibleUsersNotPayedList">{{ $t('admin_statistics.users_not_payed_list') }}</h3>

        <table v-if="isVisibleUsersNotPayedList">
          <tr>
            <td>{{ $t('admin_statistics.id') }}</td>
            <td>{{ $t('admin_statistics.company') }}</td>
            <td>{{ $t('admin_statistics.name') }}</td>
            <td>{{ $t('admin_statistics.email') }}</td>
            <td>{{ $t('admin_statistics.phone') }}</td>
            <td>{{ $t('admin_statistics.currency') }}</td>
            <td>{{ $t('admin_statistics.address') }}</td>
          </tr>
          <tr
            v-for="user in users.notPayed"
            :key="user.id"
          >
            <td>{{ user.id }}</td>
            <td>{{ user.contact?.company }}</td>
            <td>{{ user.contact?.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.contact?.phone }}</td>
            <td>{{ user.currency }}</td>
            <td>{{ user.contact?.address }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainData: {
        users: {},
        tariffs: {},
      },
      users: {
        payed: [],
        notPayed: []
      },
      isVisibleUsersPayedList: true,
      isVisibleUsersNotPayedList: true,
    }
  },
  mounted() {
    if(Number(this.$userStore.roleId) !== 4) {
      this.$router.push('/')
    }

    this.loadMainData()
    this.loadAllUsers()
  },
  methods: {
    loadMainData () {
      this.$api.get(`/v1/admin-statistics/main/${this.$userStore.userId}`)
        .then((res) => {
          const { data } = res

          this.mainData.users = data.users
          this.mainData.tariffs = data.tariffs
        })
    },
    loadAllUsers () {
      this.$api.get(`/v1/admin-statistics/users/${this.$userStore.userId}`)
        .then((res) => {
          const { data } = res

          this.users.payed = data.payed
          this.users.notPayed = data.notPayed
        })
    },
    toggleVisibleUsersPayedList () {
      this.isVisibleUsersPayedList = !this.isVisibleUsersPayedList
    },
    toggleVisibleUsersNotPayedList () {
      this.isVisibleUsersNotPayedList = !this.isVisibleUsersNotPayedList
    }

  }
}
</script>
