export default {
  ru: {
    main: {
      errors: {
        unexpected_error: "Произошла непредвиденная ошибка",
      },
    },
    months: {
      jan: "Январь",
      feb: "Февраль",
      mar: "Март",
      apr: "Апрель",
      may: "Май",
      june: "Июнь",
      july: "Июль",
      aug: "Август",
      sep: "Сентябрь",
      oct: "Октябрь",
      nov: "Ноябрь",
      dec: "Декабрь",
    },
    page_title: {
      authorization: "Авторизация",
      registration: "Регистрация",
      password_recovery: "Восстановление пароля",
      types_of_expenses: "Типы расходов",
      events: "События",
      user_list: "Список пользователей",
      statistics: "Статистика",
      task_manager: "Менеджер задач",
      personal_account: "Личный кабинет",
      settings: "Настройки",
      admin_statistics: "Статистика",
    },
    event_statuses: {
      // Содержит данные сохраненные в БД для модели EventStatuses
      in_progress: "В работе",
      closed: "Закрыт",
    },
    credit_statuses: {
      // Содержит данные сохраненные в БД для модели CreditStatuses
      on_performance: "На исполнении",
      completed: "Исполнен",
    },
    debit_statuses: {
      // Содержит данные сохраненные в БД для модели DebitStatuses
      on_performance: "На исполнении",
      completed: "Исполнен",
    },
    roles: {
      // Содержит данные сохраненные в БД для модели Roles
      company: "Компания",
      manager: "Менеджер",
      client: "Клиент",
      supplier: "Поставщик",
    },
    menu: {
      personal_account: "Личный кабинет",
      events: "События",
      statistics: "Статистика",
      users: "Пользователи",
      types_of_expenses: "Типы расходов",
      change_language_to_ru: "Change language to English",
      change_language_to_en: "Сменить язык на Русский",
      news: "t.me/sakora - Новости",
      exit: "Выход",
      task_manager: "Менеджер задач",
      settings: "Настройки",
      admin_statistics: "Статистика",
    },
    auth: {
      authorization: "Авторизация",
      login: "Войти",
      email: "Email",
      password: "Пароль",
      recover_password: "Восстановить пароль",
      registration: "Регистрация",
      statuses: {
        given_ten_free_events: "Вам выдали десять бесплатных событий",
      },
      errors: {
        invalid_login_or_password: "Неверный логин или пароль",
      },
    },
    registration: {
      registration: "Регистрация",
      personal_information: "Личная информация",
      save: "Сохранить",
      email: "Email",
      password: "Пароль",
      password_repeat: "Повторите пароль",
      recover_password: "Восстановить пароль",
      contacts: {
        name: "Имя",
        phone_number: "Номер телефона",
        company: "Название компании",
        address: "Адрес",
        description: "Описание",
      },
      authorization: "Авторизация",
      errors: {
        passwords_dont_match: "Пароли не совпадают",
        email_already_registered: "Такой email уже зарегистрирован",
        registration_error: "Ошибка регистрации",
      },
    },
    dashboard: {
      statistics: {
        general_statistics: "Общая статистика",
        completed: "Исполненные",
        enrollments: "Зачисления",
        cost: "Расходы",
        remainder: "Остаток",
      },
      errors: {
        error_getting_statistics: "Ошибка получения статистики",
      },
    },
    confirmation_dialog: {
      confirm: "Подтвердить",
      cancel: "Отмена",
    },
    create_credit_type: {
      adding_a_flow_type: "Добавление типа расхода",
      expense_name: "Название расхода",
      flow_type_name: "Название типа расхода",
      save: "Сохранить",
      errors: {
        flow_type_added: "Тип расхода добавлен",
        failed_add_expense_type: "Не удалось добавить тип расхода",
      },
    },
    payment_modal: {
      account_has_run_out_available_events:
        "На вашем аккаунте закончились доступные события",
      description:
        "Для того, чтобы добавить новое событие, вам необходимо приобрести один из доступных тарифных планов. Доступ к добавлению событий будет восстановлен после оплаты, в течение 10 минут!",
      choose_tariff: "Выбирите тарифный план",
      pay: "Оплатить",
      errors: {
        flow_type_added: "Тип расхода добавлен",
        failed_add_expense_type: "Не удалось добавить тип расхода",
      },
    },
    edit_credit_type: {
      editing_flow_type: "Редактирование типа расхода",
      expense_name: "Название расхода",
      flow_type_name: "Название типа расхода",
      save: "Сохранить",
      statuses: {
        flow_type_updated: "Тип расхода обновлен",
      },
      errors: {
        failed_add_expense_type: "Не удалось добавить тип расхода",
        failed_retrieve_flow_type_data:
          "Не удалось получить данные о типе расхода",
      },
    },
    credit_types_view: {
      add_expense_type: "+ Добавить тип расхода",
      name: "Название",
      expense_types_found: "Типы расходов не найдены",
      errors: {
        getting_expense_types: "Ошибка получения типов расходов",
      },
    },
    contact_modal: {
      fill_in_your_contact_details: "Заполните контактные данные",
      name: "Имя",
      phone_number: "Номер телефона",
      company_name: "Название компании",
      address: "Адрес",
      save: "Сохранить",
      errors: {
        failed_save_contact_details: "Не удалось сохранить контактные данные",
      },
    },
    multi_select_wr: {
      choose: "Выбрать",
      cancel: "Отменить",
      selected: "Выбрано",
      empty: "Пусто",
      nothing_found: "Ничего не найдено",
    },
    create_credit_modal: {
      adding_an_expense: "Добавление расхода",
      date: "Дата",
      flow_type: "Тип расхода",
      select_flow_type: "Выберите тип расхода",
      expense_name: "Название расхода",
      expense_amount: "Сумма расхода",
      status: "Статус",
      select_status: "Выберите статус",
      check: "Чек (до 3 MB)",
      attached_file: "Прикрепленный файл",
      save: "Сохранить",
      statuses: {
        expense_added: "Расход добавлен",
      },
      errors: {
        error_receiving_data: "Ошибка при получении данных",
        error_getting_statuses: "Ошибка при получении статусов расходов",
        error_update: "Ошибка обновления данных",
        attachment_error: "Файл должен быть меньше чем 3 MB",
        add_expense_type: "Для создания добавьте тип расхода",
      },
    },
    create_debit_modal: {
      adding_a_receipt: "Добавление поступления",
      date: "Дата",
      title_of_receipt: "Название поступления",
      receipt_amount: "Сумма поступления",
      status: "Статус",
      select_status: "Выберите статус",
      save: "Сохранить",
      statuses: {
        receipt_added: "Поступление добавлено",
      },
      errors: {
        error_receiving_data: "Ошибка при получении данных",
        error_adding_data: "Ошибка добавления данных",
      },
    },
    create_event_modal: {
      create_an_events: "Создание события",
      event_name: "Название события",
      date: "Дата",
      search_placeholder: "Название события",
      manager: "Менеджер",
      select_manager: "Выберите менеджера",
      start_typing_managers_name: "Начните вводить имя менеджера",
      observers: "Наблюдатели",
      who_can_view_the_event: "Кто может просматривать событие",
      start_typing_employee_name: "Начните вводить имя сотрудника",
      editors: "Редакторы",
      who_can_edit_an_event: "Кто может редактировать событие",
      event_status: "Статус события",
      select_status: "Выберите статус",
      supplier: "Поставщик",
      select_supplier: "Выберите поставщика",
      start_typing_the_supplier_name: "Начните вводить имя поставщика",
      client: "Клиент",
      select_client: "Выберите клиента",
      start_typing_customer_name: "Начните вводить название клиента",
      new_client: "Новый клиент",
      email: "Email",
      password: "Пароль",
      client_name: "Имя клиента",
      phone: "Телефон",
      address: "Адрес",
      company: "Компания",
      save: "Сохранить",
      add_new_client: "+ Новый клиент",
      add_already_created_client: "Добавить уже существующего клиента",
      add_already_created_manager: "Добавить уже существующего менеджера",
      input_email: "Введите Email",
      loading: "Загрузка",
      search: "Поиск",
      statuses: {
        deal_added: "Сделка добавлена",
        user_found: "Пользователь найден",
        user_notfound: "Пользователь не найден",
      },
      errors: {
        error_while_receiving_data: "Ошибка при получении данных",
        failed_get_information_about_managers:
          "Не удалось получить информацию о менеджерах",
        failed_retrieve_customer_information:
          "Не удалось получить информацию о клиентах",
        failed_get_list_suppliers: "Не удалось получить список поставщиков",
        data_update_error: "Ошибка обновления данных",
      },
    },
    edit_credit_modal: {
      editing_consumption: "Редактирование расхода",
      date: "Дата",
      flow_type: "Тип расхода",
      select_flow_type: "Выберите тип расхода",
      expense_name: "Название расхода",
      system_commission: "Комиссия системы (%)",
      expense_amount: "Сумма расхода",
      status: "Статус",
      select_status: "Выберите статус",
      check: "Чек (до 3 MB)",
      attached_file: "Прикрепленный файл",
      save: "Сохранить",
      statuses: {
        data_updated: "Данные обновлены",
      },
      errors: {
        error_receiving_data: "Ошибка при получении данных",
        error_getting_expense_statuses:
          "Ошибка при получении статусов расходов",
        error_data_update: "Ошибка обновления данных",
        attachment_error: "Файл должен быть меньше чем 3 MB",
      },
    },
    edit_debit_modal: {
      editing_receipt: "Редактирование поступления",
      date: "Дата",
      title_receipt: "Название поступления",
      receipt_amount: "Сумма поступления",
      status: "Статус",
      select_status: "Выберите статус",
      save: "Сохранить",
      statuses: {
        receipt_added: "Поступление добавлено",
        receipt_updated: "Поступление обновлено",
      },
      errors: {
        error_receiving_data: "Ошибка при получении данных",
        error_adding_data: "Ошибка добавления данных",
        error_data_update: "Ошибка обновления данных",
        failed_get_admission_details:
          "Не удалось получить данные о поступлении",
      },
    },
    edit_event_modal: {
      edit_event: "Редактирование события",
      event_name: "Название события",
      date: "Дата",
      search_placeholder: "Название события",
      manager: "Менеджер",
      select_manager: "Выберите менеджера",
      start_typing_manager_name: "Начните вводить имя менеджера",
      viewers: "Наблюдатели",
      who_can_view_the_event: "Кто может просматривать событие",
      start_typing_employee_name: "Начните вводить имя сотрудника",
      editors: "Редакторы",
      who_can_edit_the_event: "Кто может редактировать событие",
      event_status: "Статус события",
      select_status: "Выберите статус",
      customer: "Клиент",
      select_customer: "Выберите клиента",
      start_typing_the_customer_name: "Начните вводить название клиента",
      save: "Сохранить",
      statuses: {
        data_updated: "Данные обновлены",
      },
      errors: {
        error_while_getting_data: "Ошибка при получении данных",
        failed_get_manager_information:
          "Не удалось получить информацию о менеджерах",
        failed_get_customer_information:
          "Не удалось получить информацию о клиентах",
        error_updating_data: "Ошибка обновления данных",
        failed_get_event_data: "Не удалось получить данные о событии",
        failed_get_supplier_data: "Не удалось получить данные о поставщиках",
      },
    },
    single_event: {
      receipts: "Поступления",
      expenses: "Расходы",
      bottom_line: "Итог",
      messages: {
        confirm_deletion: "Подтвердите удаление",
        are_you_sure_want_delete_event:
          "Вы действительно хотите удалить событие?",
      },
      errors: {
        insufficient_rights_delete_object:
          "Недостаточно прав для удаления объекта",
      },
    },
    single_event_credits: {
      date: "Дата",
      expense_name: "Название расхода",
      documents: "Документы",
      expense: "Расход",
      status: "Статус",
      download_document: "Скачать документ",
      show_all_expenses: "Показать все расходы",
      add_expense: "+ Добавить расход",
      messages: {
        confirm_deletion: "Подтвердите удаление",
        are_you_sure_want_delete_expenses:
          "Вы действительно хотите удалить расход?",
      },
      errors: {
        insufficient_rights_delete_object:
          "Недостаточно прав для удаления объекта",
      },
    },
    single_event_debits: {
      date: "Дата",
      title_receipt: "Название поступления",
      receipt: "Поступление",
      status: "Статус",
      show_all_receipts: "Показать все поступления",
      add_receipt: "+ Добавить поступление",
      messages: {
        confirm_deletion: "Подтвердите удаление",
        are_you_sure_want_delete_receipt:
          "Вы действительно хотите удалить поступление?",
      },
      errors: {
        failed_receive_receipts: "Не удалось получить поступления",
        insufficient_rights_delete_object:
          "Недостаточно прав для удаления объекта",
      },
    },
    single_event_operations: {
      filter_by_dates: "Фильтр по датам",
      search: "Поиск по операциям",
      search_placeholder: "Название операции или сумма",
      date: "Дата",
      operation: "Операция",
      documents: "Документы",
      coming: "Приход",
      consumption: "Расход",
      status: "Статус",
      download_document: "Скачать документ",
      add_expense: "+ Добавить расход",
      add_receipt: "+ Добавить поступление",
      messages: {
        confirm_deletion: "Подтвердите удаление",
        are_you_sure_want_delete_expense:
          "Вы действительно хотите удалить расход?",
        are_you_sure_want_delete_event:
          "Вы действительно хотите удалить поступление?",
      },
      errors: {
        failed_receive_receipts: "Не удалось получить поступления",
        insufficient_rights_delete_object:
          "Недостаточно прав для удаления объекта",
      },
    },
    events_list: {
      search: "Поиск",
      filters: "Фильтры",
      event_status: "Статус события",
      operation_status: "Статус операции",
      create_event: "Создать событие",
      loading: "Загрузка...",
      errors: {
        failed_retrieve_event_data_from_server:
          "Не удалось получить данные о событиях с сервера",
        failed_get_event_statuses: "Не удалось получить статусы событий",
        failed_get_operation_statuses: "Не удалось получить статусы операций",
      },
    },
    statistics_view: {
      download_xls: "Загрузить XLS",
      select_dates: "Выберите даты",
      general_cost_schedule: "Общий график расходов",
      managers: "Менеджеры",
      select_managers: "Выберите менеджеров",
      clients: "Клиенты",
      select_clients: "Выберите клиентов",
      event_statuses: "Статусы событий",
      select_status: "Выберите статус",
      expense_statuses: "Статусы расходов",
      receipt_statuses: "Статусы поступлений",
      receipts: "Поступления",
      expenses: "Расходы",
      remainder: "Остаток",
      expense_statistics: "Статистика расходов",
      flow_type: "Тип расхода",
      sum: "Сумма",
      receipt: "Поступление",
      expense: "Расход",
      all_statuses: "Все статусы",
      errors: {
        failed_get_expense_types: "Не удалось получить типы расходов",
        failed_get_events: "Не удалось получить события",
        failed_get_event_statuses: "Не удалось получить статусы событий",
        failed_get_expense_statuses: "Не удалось получить статусы расходов",
        failed_get_receipt_statuses: "Не удалось получить статусы поступлений",
      },
    },
    create_user_modal: {
      adding_user: "Добавление пользователя",
      role: "Роль",
      name: "Имя",
      email: "Email",
      phone_number: "Номер телефона",
      password: "Пароль",
      company_name: "Название компании",
      address: "Адрес",
      save: "Сохранить",
      statuses: {
        user_added: "Пользователь добавлен",
      },
      errors: {
        error_while_receiving_data: "Ошибка при получении данных",
        error_adding_user: "Ошибка добавления пользователя",
      },
    },
    edit_user_modal: {
      editing_user: "Редактирование пользователя",
      name: "Имя",
      email: "Email",
      phone_number: "Номер телефона",
      company_name: "Название компании",
      address: "Адрес",
      save: "Сохранить",
      messages: {
        user_details_updated: "Данные пользователя обновлены",
      },
      errors: {
        error_updating_user_data: "Ошибка обновления данных о пользователе",
        error_retrieving_user_data_try_again:
          "Ошибка получения данных о пользователе. Попробуйте еще раз.",
      },
    },
    user_list_view: {
      add_user: "Добавить пользователя",
      errors: {
        failed_get_user_data: "Не удалось получить данные о пользователях",
      },
    },
    new_password: {
      new_password: "Новый пароль",
      password: "Пароль",
      password_repeat: "Повторите пароль",
      reset_password: "Сбросить пароль",
      authorization: "Авторизация",
      registration: "Регистрация",
      messages: {
        password_reset_successfully: "Пароль успешно сброшен",
      },
      errors: {
        passwords_dont_match: "Пароли не совпадают",
        password_reset_error: "Ошибка сброса пароля",
      },
    },
    password_recovery: {
      link_sent: "Ссылка отправлена",
      link_sent_to_email: "Ссылка для сброса пароля отправлена на ваш email!",
      reset_password: "Сбросить пароль",
      email: "Email",
      authorization: "Авторизация",
      registration: "Регистрация",
      messages: {
        link_reset_sent_to_email:
          "Ссылка на восстановление пароля отправлена вам на почту",
      },
      errors: {
        password_reset_error: "Ошибка сброса пароля",
      },
    },
    tasks: {
      other: {
        loading: "Загрузка...",
        confirmDeletion: "Вы действительно хотите удалить задачу?",
      },
      toast: {
        updatedSuccessfully: "Задача успешно обновлена",
        updateFailed: "Не удалось обновить задачу",
        deleteFailed: "Не удалось удалить задачу",
        deletedSuccessfully: "Задача успешно удалена",
      },
      days: {
        Monday: "Понедельник",
        Tuesday: "Вторник",
        Wednesday: "Среда",
        Thursday: "Четверг",
        Friday: "Пятница",
        Saturday: "Суббота",
        Sunday: "Воскресенье",
      },
      columns: {
        Monday: "Понедельник",
        Tuesday: "Вторник",
        Wednesday: "Среда",
        Thursday: "Четверг",
        Friday: "Пятница",
        Saturday: "Суббота",
        Sunday: "Воскресенье",
        Completed: "Выполнено",
      },
      actions: {
        addNew: "Добавить новую задачу",
      },
      modal: {
        addTask: {
          title: "Добавить задачу",
          priorities: {
            Low: "Низкий",
            Medium: "Средний",
            High: "Высокий",
          },
          dueDate: "Выберите срок выполнения",
          dueDateEmpty: "Выберите срок выполнения",
          action: "Добавить",
          actionEdit: "Обновить",
          actionIsEditing: "Обновляем...",
          actionProcessing: "Добавляем...",

          taskTitle: "Название задачи",
          taskTitleEmpty: "Введите название задачи",
          taskDescription: "Описание задачи",
          taskDescriptionEmpty: "Введите описание задачи",
          selectPriority: "Выберите приоритет",
          selectPriorityEmpty: "Выберите приоритет",
          selectUser: "Выберите исполнителя",
          selectUserEmpty: "Выберите исполнителя",
          selectWatcher: "Выберите наблюдателя",
          selectWatcherEmpty: "Выберите наблюдателя",
        },
        editTask: {
          title: "Редактировать задачу",
        },
        viewTask: {
          title: "Просмотр задачи",
        },
      },
      item: {
        assignee: "Исполнитель",
        watcher: "Наблюдатель",
      },
    },
    dropdown: {
      view: "Просмотр",
      edit: "Редактировать",
      delete: "Удалить",
      password_reset_error: "Ошибка сброса пароля",
    },
    personal_account: {
      personal_information: "Личная информация",
      tariff: "Тариф",
      form: {
        personal_information: {
          name: "Имя",
          email: "Email",
          phone_number: "Номер телефона",
          company_name: "Название компании",
          address: "Адрес",
          description: "Описание",
          old_password: "Старый пароль",
          new_password: "Новый пароль",
          currency: "Валюта",
          save: "Сохранить",
          error: {
            get: "Ошибка при плучении данных",
            change: "Ошибка при изменении данных",
          },
          statuses: {
            updates_success: "Данные успешно обновлены",
          },
        },
      },
      info: {
        title_tariff: "Информация по вашему тарифу",
        your_tariff: "Ваш тариф",
        event_count: "Всего событий",
        out_of: "из",
        change_tariff: "Сменить тариф",
        main_info: "Основная информация",
        change_password: "Смена пароля",
        currency: "Валюта",
      },
    },
    mobile: {
      title: "Извините, сайт не поддерживает мобильные устройства",
    },
    settings: {
      addition: "Добавление",
      addition_user: {
        addition: "Добавить пользователя",
        name: "Имя",
        email: "Email",
        phone_number: "Номер телефона",
        organization: "Email компании",
        role: "Роль",
        address: "Адрес",
        description: "Описание",
        password: "Пароль",
        currency: "Валюта",
        organization_name: "Название компании",
        save: "Сохранить",
        error: {
          get: "Ошибка при плучении данных",
          change: "Ошибка при изменении данных",
        },
        statuses: {
          updates_success: "Данные успешно обновлены",
        },
      },
      addition_company: {
        addition: "Добавить компанию",
        name: "Название",
        save: "Сохранить",
        error: {
          get: "Ошибка при плучении данных",
          change: "Ошибка при изменении данных",
        },
        statuses: {
          updates_success: "Данные успешно обновлены",
        },
      },
    },
    admin_statistics: {
      users: "Пользователи",
      tariffs: "Тарифы",
      count_users: "Общее кол. пользователей",
      count_payed: "Кол. пользователей оплативших тариф",
      count_not_payed: "Кол. пользователей не оплативших тариф",
      users_payed_list: "Список оплативших пользователей",
      users_not_payed_list: "Список не оплативших пользователей",
      id: "id",
      company: "Компания",
      name: "Имя",
      email: "Email",
      phone: "Телефон",
      currency: "Валюта",
      address: "Адрес",
      event_count: "Кол. событий",
    },
  },
  en: {
    main: {
      errors: {
        unexpected_error: "An unexpected error occurred",
      },
      mobile_version_in_dev: "Mobile version is in development!",
      go_main_page: "Go main page ->",
    },
    months: {
      jan: "January",
      feb: "February",
      mar: "March",
      apr: "April",
      may: "May",
      june: "June",
      july: "July",
      aug: "August",
      sep: "September",
      oct: "October",
      nov: "November",
      dec: "December",
    },
    page_title: {
      authorization: "Authorization",
      registration: "Registration",
      password_recovery: "Password recovery",
      types_of_expenses: "Types of expenses",
      events: "Events",
      user_list: "User list",
      statistics: "Statistics",
      task_manager: "Task manager",
      personal_account: "Personal Account",
      settings: "Settings",
      admin_statistics: "Statistics",
    },
    event_statuses: {
      // Содержит данные сохраненные в БД для модели EventStatuses
      in_progress: "In progress",
      closed: "Closed",
    },
    credit_statuses: {
      // Содержит данные сохраненные в БД для модели CreditStatuses
      on_performance: "On performance",
      completed: "Completed",
    },
    debit_statuses: {
      // Содержит данные сохраненные в БД для модели DebitStatuses
      on_performance: "On performance",
      completed: "Completed",
    },
    roles: {
      // Содержит данные сохраненные в БД для модели Roles
      company: "Company",
      manager: "Manager",
      client: "Client",
      supplier: "Supplier",
    },
    menu: {
      personal_account: "Personal Account",
      events: "Events",
      statistics: "Statistics",
      users: "Users",
      types_of_expenses: "Types of expenses",
      change_language_to_ru: "Сменить язык на Русский",
      change_language_to_en: "Change language to English",
      news: "t.me/sakora - News",
      exit: "Exit",
      task_manager: "Task manager",
      settings: "Settings",
      admin_statistics: "Statistics",
    },
    auth: {
      authorization: "Authorization",
      login: "Login",
      email: "Email",
      password: "Password",
      recover_password: "Recover password",
      registration: "Registration",
      statuses: {
        given_ten_free_events: "You were given ten free events",
      },
      errors: {
        invalid_login_or_password: "Invalid login or password",
      },
    },
    registration: {
      registration: "Registration",
      personal_information: "Personal information",
      save: "Сохранить",
      email: "Email",
      password: "Password",
      password_repeat: "Repeat password",
      recover_password: "Recover password",
      authorization: "Authorization",
      contacts: {
        name: "Name",
        phone: "Phone number",
        company: "Company name",
        address: "Address",
        description: "Description",
      },
      errors: {
        passwords_dont_match: "Passwords don't match",
        email_already_registered: "This email has already been registered",
        registration_error: "Registration error",
      },
    },
    dashboard: {
      statistics: {
        general_statistics: "General statistics",
        completed: "Completed",
        enrollments: "Enrollments",
        cost: "Cost",
        remainder: "Remainder",
      },
      errors: {
        error_getting_statistics: "Error getting statistics",
      },
    },
    confirmation_dialog: {
      confirm: "Confirm",
      cancel: "Cancel",
    },
    create_credit_type: {
      adding_a_flow_type: "Adding a flow type",
      expense_name: "Expense name",
      flow_type_name: "Flow type name",
      save: "Save",
      errors: {
        flow_type_added: "Flow type added",
        failed_add_expense_type: "Failed to add expense type",
      },
    },
    payment_modal: {
      account_has_run_out_available_events:
        "Your account has run out of available events",
      description:
        "In order to add a new event, you need to purchase one of the available plans. Access to adding events will be restored after payment, within 10 minutes!",
      choose_tariff: "Choose a tariff plan",
      pay: "Pay",
      errors: {},
    },
    edit_credit_type: {
      editing_flow_type: "Editing a Flow Type",
      expense_name: "Expense name",
      flow_type_name: "Flow type name",
      save: "Save",
      statuses: {
        flow_type_updated: "Flow type updated",
      },
      errors: {
        failed_add_expense_type: "Failed to add expense type",
        failed_retrieve_flow_type_data: "Failed to retrieve flow type",
      },
    },
    credit_types_view: {
      add_expense_type: "+ Add expense type",
      name: "Name",
      expense_types_found: "No expense types found",
      errors: {
        getting_expense_types: "Error getting expense types",
      },
    },
    contact_modal: {
      fill_in_your_contact_details: "Fill in your contact details",
      name: "Name",
      phone_number: "Phone number",
      company_name: "Company name",
      address: "Address",
      description: "Description",
      save: "Save",
      errors: {
        failed_save_contact_details: "Failed to save contact details",
      },
    },
    multi_select_wr: {
      choose: "Choose",
      cancel: "Cancel",
      selected: "Selected",
      empty: "Empty",
      nothing_found: "Nothing found",
    },
    create_credit_modal: {
      adding_an_expense: "Adding an expense",
      date: "Date",
      flow_type: "Flow type",
      select_flow_type: "Select flow type",
      expense_name: "Expense name",
      expense_amount: "Expense amount",
      status: "Status",
      select_status: "Select status",
      check: "Check (up to 3 MB)",
      attached_file: "Attached file",
      save: "Save",
      statuses: {
        expense_added: "Expense added",
      },
      errors: {
        error_receiving_data: "Error while receiving data",
        error_getting_statuses: "Error when getting expense statuses",
        error_update: "Data update error",
        attachment_error: "The file size must be less than 3 MB.",
        add_expense_type: "To create, add expense type",
      },
    },
    create_debit_modal: {
      adding_a_receipt: "Adding a receipt",
      date: "Date",
      title_of_receipt: "Title of receipt",
      receipt_amount: "Receipt amount",
      status: "Status",
      select_status: "Select status",
      save: "Save",
      statuses: {
        receipt_added: "Receipt added",
      },
      errors: {
        error_receiving_data: "Error while receiving data",
        error_adding_data: "Error adding data",
      },
    },
    create_event_modal: {
      event_name: "Event name",
      create_an_events: "Create an events",
      date: "Date",
      search_placeholder: "Event name",
      manager: "Manager",
      select_manager: "Select a manager",
      start_typing_managers_name: "Start typing the manager's name",
      observers: "Observers",
      who_can_view_the_event: "Who can view the event",
      start_typing_employee_name: "Start typing employee name",
      editors: "Editors",
      who_can_edit_an_event: "Who can edit an event",
      event_status: "Event status",
      select_status: "Select status",
      supplier: "Supplier",
      select_supplier: "Select supplier",
      start_typing_the_supplier_name: "Start typing the supplier name",
      client: "Client",
      select_client: "Select client",
      start_typing_customer_name: "Start typing customer name",
      new_client: "New client",
      email: "Email",
      password: "Password",
      client_name: "Client name",
      phone: "phone",
      address: "Address",
      company: "Company",
      save: "Save",
      add_new_client: "+ New client",
      add_already_created_client: "Add an existing client",
      add_already_created_manager: "Add an existing manager",
      input_email: "Input Email",
      loading: "Loading",
      search: "Search",
      statuses: {
        deal_added: "Deal added",
        user_found: "User found",
        user_notfound: "User notfound",
      },
      errors: {
        error_while_receiving_data: "Error while receiving data",
        failed_get_information_about_managers:
          "Failed to get information about managers",
        failed_retrieve_customer_information:
          "Failed to retrieve customer information",
        failed_get_list_suppliers: "Failed to get list of suppliers",
        data_update_error: "Data update error",
      },
    },
    edit_credit_modal: {
      editing_consumption: "Editing consumption",
      date: "Date",
      flow_type: "Flow type",
      select_flow_type: "Select flow type",
      expense_name: "Expense name",
      system_commission: "System commission (%)",
      expense_amount: "Expense amount",
      status: "Status",
      select_status: "Select status",
      check: "Check (up to 3 MB)",
      attached_file: "Attached file (up to 3 MB)",
      save: "Save",
      statuses: {
        data_updated: "Data updated",
      },
      errors: {
        error_receiving_data: "Error while receiving data",
        error_getting_expense_statuses: "Error when getting expense statuses",
        error_data_update: "Data update error",
        attachment_error: "The file size must be less than 3 MB.",
      },
    },
    edit_debit_modal: {
      editing_receipt: "Editing a receipt",
      date: "Date",
      title_receipt: "Title of receipt",
      receipt_amount: "Receipt amount",
      status: "Status",
      select_status: "Select status",
      save: "Save",
      statuses: {
        receipt_added: "Receipt added",
        receipt_updated: "Receipt updated",
      },
      errors: {
        error_receiving_data: "Error while receiving data",
        error_adding_data: "Error adding data",
        error_data_update: "Data update error",
        failed_get_admission_details: "Failed to get admission details",
      },
    },
    edit_event_modal: {
      edit_event: "Edit Event",
      event_name: "Event name",
      date: "Date",
      search_placeholder: "Event Name",
      manager: "Manager",
      select_manager: "Select Manager",
      start_typing_manager_name: "Start typing Manager name",
      viewers: "Viewers",
      who_can_view_the_event: "Who can view the event",
      start_typing_employee_name: "Start typing the employee name",
      editors: "Editors",
      who_can_edit_the_event: "Who can edit the event",
      event_status: "Event status",
      select_status: "Select a status",
      customer: "Customer",
      select_customer: "Select a customer",
      start_typing_the_customer_name: "Start typing the customer name",
      save: "Save",
      statuses: {
        data_updated: "Data updated",
      },
      errors: {
        error_while_getting_data: "Error while getting data",
        failed_get_manager_information: "Failed to get manager information",
        failed_get_customer_information: "Failed to get customer information",
        error_updating_data: "Error updating data",
        failed_get_event_data: "Failed to get event data",
        failed_get_supplier_data: "Failed to get supplier data",
      },
    },
    single_event: {
      receipts: "Receipts",
      expenses: "Expenses",
      bottom_line: "Bottom line",
      messages: {
        confirm_deletion: "Confirm deletion",
        are_you_sure_want_delete_event:
          "Are you sure you want to delete the event?",
      },
      errors: {
        insufficient_rights_delete_object:
          "Insufficient rights to delete an object",
      },
    },
    single_event_credits: {
      date: "Date",
      expense_name: "Expense name",
      documents: "Documents",
      consumption: "Consumption",
      status: "Status",
      download_document: "Download document",
      show_all_expenses: "Show all expenses",
      add_expense: "+ Add expense",
      messages: {
        confirm_deletion: "Confirm deletion",
        are_you_sure_want_delete_event:
          "Are you sure you want to delete the expense?",
      },
      errors: {
        insufficient_rights_delete_object:
          "Insufficient rights to delete an object",
      },
    },
    single_event_debits: {
      date: "Date",
      title_receipt: "Title of receipt",
      admission: "Admission",
      status: "Status",
      show_all_receipts: "Show all receipts",
      add_receipt: "+ Add receipt",
      messages: {
        confirm_deletion: "Confirm deletion",
        are_you_sure_want_delete_event:
          "Are you sure you want to delete the expense?",
      },
      errors: {
        failed_receive_receipts: "Failed to receive receipts",
        insufficient_rights_delete_object:
          "Insufficient rights to delete an object",
      },
    },
    single_event_operations: {
      filter_by_dates: "Filter by dates",
      search: "Search by operations",
      search_placeholder: "Event operations or sum",
      date: "Date",
      operation: "Operation",
      documents: "Documents",
      coming: "Coming",
      consumption: "Consumption",
      status: "Status",
      download_document: "Download document",
      add_expense: "+ Add expense",
      add_receipt: "+ Add receipt",
      messages: {
        confirm_deletion: "Confirm deletion",
        are_you_sure_want_delete_event:
          "Are you sure you want to delete the expense?",
      },
      errors: {
        failed_receive_receipts: "Failed to receive receipts",
        insufficient_rights_delete_object:
          "Insufficient rights to delete an object",
      },
    },
    events_list: {
      search: "Search",
      filters: "Filters",
      event_status: "Event status",
      operation_status: "Operation status",
      create_event: "Create an event",
      loading: "Loading...",
      errors: {
        failed_retrieve_event_data_from_server:
          "Failed to retrieve event data from server",
        failed_get_event_statuses: "Failed to get event statuses",
        failed_get_operation_statuses: "Failed to get operation statuses",
      },
    },
    statistics_view: {
      download_xls: "Download XLS",
      select_dates: "Select dates",
      general_cost_schedule: "General cost schedule",
      managers: "Managers",
      select_managers: "Select managers",
      clients: "Clients",
      select_clients: "Select clients",
      event_statuses: "Event statuses",
      select_status: "Select status",
      expense_statuses: "Expense statuses",
      receipt_statuses: "Receipt statuses",
      receipts: "Receipts",
      expenses: "Expenses",
      balance: "Balance",
      expense_statistics: "Expense statistics",
      flow_type: "Flow type",
      sum: "Sum",
      receipt: "Receipt",
      expense: "Expense",
      all_statuses: "All statuses",
      errors: {
        failed_get_expense_types: "Failed to get expense types",
        failed_get_events: "Failed to get events",
        failed_get_event_statuses: "Failed to get event statuses",
        failed_get_expense_statuses: "Failed to get expense statuses",
        failed_get_receipt_statuses: "Failed to get receipt statuses",
      },
    },
    create_user_modal: {
      adding_user: "Adding a user",
      role: "Role",
      name: "Name",
      email: "Email",
      phone_number: "Phone number",
      password: "Password",
      company_name: "Company name",
      address: "Address",
      description: "Description",
      save: "Save",
      statuses: {
        user_added: "User added",
      },
      errors: {
        error_while_receiving_data: "Error while receiving data",
        error_adding_user: "Error adding user",
      },
    },
    edit_user_modal: {
      editing_user: "Editing a user",
      name: "Name",
      email: "Email",
      phone_number: "Phone number",
      company_name: "Company name",
      address: "Address",
      save: "Save",
      messages: {
        user_details_updated: "User details updated",
      },
      errors: {
        error_updating_user_data: "Error updating user data",
        error_retrieving_user_data_try_again:
          "Error retrieving user data. Try again.",
      },
    },
    user_list_view: {
      add_user: "Add user",
      errors: {
        failed_get_user_data: "Failed to get user data",
      },
    },
    new_password: {
      new_password: "New Password",
      password: "Password",
      password_repeat: "Repeat password",
      reset_password: "Reset password",
      authorization: "Authorization",
      registration: "Registration",
      messages: {
        password_reset_successfully: "Password reset successfully",
      },
      errors: {
        passwords_dont_match: "Passwords don't match",
        password_reset_error: "Password reset error",
      },
    },
    password_recovery: {
      link_sent: "Link sent",
      link_sent_to_email: "A password reset link has been sent to your email!",
      reset_password: "Reset password",
      email: "Email",
      authorization: "Authorization",
      registration: "Registration",
      messages: {
        link_reset_sent_to_email:
          "A link to reset your password has been sent to your email.",
      },
      errors: {
        password_reset_error: "Password reset error",
      },
    },
    personal_account: {
      personal_information: "Personal information",
      tariff: "Tariff",
      form: {
        personal_information: {
          name: "Name",
          email: "Email",
          phone_number: "Phone number",
          address: "Address",
          company_name: "Company name",
          description: "Description",
          save: "Save",
          old_password: "Old password",
          new_password: "New password",
          currency: "Currency",
          error: {
            get: "Error to get data",
            change: "Error to change data",
          },
          statuses: {
            updates_success: "Data updated successfully",
          },
        },
      },
      info: {
        title_tariff: "Information on your tariff",
        your_tariff: "Your tariff",
        event_count: "Number of events",
        out_of: "out of",
        change_tariff: "Change tariff",
        main_info: "Main information",
        change_password: "Change password",
        currency: "Currency",
      },
    },
    tasks: {
      other: {
        loading: "Loading...",
      },
      toast: {
        confirmDeletion: "Are you sure you want to delete the task?",
        updatedSuccessfully: "Task updated successfully",
        updateFailed: "Failed to update task",
        deleteFailed: "Failed to delete task",
        deletedSuccessfully: "Task deleted successfully",
      },
      days: {
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday",
      },
      columns: {
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday",
        Completed: "Completed",
      },
      actions: {
        addNew: "Add new task",
      },
      modal: {
        addTask: {
          title: "Add task for {day}",
          priorities: {
            Low: "Low",
            Medium: "Medium",
            High: "High",
          },
          dueDate: "Due date",
          dueDateEmpty: "Choose due date",
          action: "Add",
          actionEdit: "Update",
          actionIsEditing: "Updating...",
          actionProcessing: "Adding...",
          taskTitle: "Task name",
          taskTitleEmpty: "Enter task name",
          taskDescription: "Task description",
          taskDescriptionEmpty: "Enter task description",
          selectPriority: "Select priority",
          selectPriorityEmpty: "Choose priority",
          selectUser: "Select assignee",
          selectUserEmpty: "Choose person",
          selectWatcher: "Select watcher",
          selectWatcherEmpty: "Choose person",
        },
        editTask: {
          title: "Edit task",
        },
        viewTask: {
          title: "View task",
        },
      },
      item: {
        assignee: "Assignee",
        watcher: "Watcher",
      },
    },
    dropdown: {
      edit: "Edit",
      delete: "Delete",
      view: "View",
    },
  },
  mobile: {
    title: "Sorry, this app is not available for mobile devices.",
  },
  settings: {
    addition: "Addition",
    addition_user: {
      addition: "Addition user",
      name: "Name",
      email: "Email",
      phone_number: "Phone number",
      address: "Address",
      organization: "Email company",
      role: "Role",
      description: "Description",
      save: "Save",
      password: "Password",
      currency: "Currency",
      organization_name: "Organization name",
      error: {
        get: "Error to get data",
        change: "Error to change data",
      },
      statuses: {
        updates_success: "Data updated successfully",
      },
    },
    addition_company: {
      addition: "Addition company",
      name: "Name",
      save: "Save",
      password: "Password",
      currency: "Currency",
      error: {
        get: "Error to get data",
        change: "Error to change data",
      },
      statuses: {
        updates_success: "Data updated successfully",
      },
    },
  },
  admin_statistics: {
    users: "Users",
    tariffs: "Tariffs",
    count_users: "Total count users",
    count_payed: "Number of users who paid the tariff",
    count_not_payed: "Number of users who have not paid the tariff",
    users_payed_list: "List of paid users",
    users_not_payed_list: "List of non-paying users",
    id: "id",
    company: "Company",
    name: "Name",
    email: "Email",
    phone: "Phone",
    currency: "Currency",
    address: "Address",
    event_count: "Event count",
  },
};
