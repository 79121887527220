<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window">
      <div class="ui-modal__heading">
        <h3>{{ $t('payment_modal.account_has_run_out_available_events') }}</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <div class="ui-modal__description">
        {{ $t('payment_modal.description') }}
      </div>

      <form class="ui-modal__form" @submit.prevent="pay">
        <div class="ui-form-group">
          <label class="ui-label">{{ $t('payment_modal.choose_tariff') }}</label>
          <select
            v-model="selectTariff"
            class="ui-input"
            required
          >
            <option
              v-for="tariff in tariffs"
              :key="tariff.code"
              :value="tariff.code"
            >+ {{ tariff.title }} - {{ $convertToCurrency(tariff.price) }}</option>
          </select>
        </div>

<!--        <div class="ui-form-group">-->
<!--          <label class="ui-label">{{ $t('payment_modal.expense_name') }}</label>-->
<!--          <input-->
<!--            type="text"-->
<!--            class="ui-input"-->
<!--            :placeholder="$t('payment_modal.flow_type_name')"-->
<!--            v-model="creditType.name"-->
<!--            required-->
<!--          />-->
<!--        </div>-->

        <button type="submit" disabled class="ui-button multiselect--disabled" v-text="$t('payment_modal.pay')" />
        <span>Эквайринг в процессе подключения! Для оплаты свяжитесь с нами <a href="https://t.me/aiverseinc" class="tg-link">в Telegram</a></span>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tariffs: [],
      selectTariff: '',
      isLoading: false
    }
  },
  mounted() {
    this.loadTariffs()
  },
  methods: {
    loadTariffs() {
      this.$api.get('/v1/tariff/list', this.creditType)
        .then(({ data }) => {
          this.tariffs = data.filter((id) => id !== 1)
          this.selectTariff = data[0].code
        })
    },
    pay() {
      const data = {
        tariff: this.selectTariff,
        userId: this.$userStore.userId
      }

      this
        .$api
        .post('/v1/tariff/create-pay', data)
        .then(() => {
          this.$emit('success')
        })
    }
  }
}
</script>