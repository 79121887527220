<template>
  <section class="information">
    <time class="information__current">
      {{ formatDate(weekDates[0]) }} - {{ formatDate(weekDates[6]) }}
    </time>
    <div class="information-action">
      <button class="information-action__prev" @click="getPreviousWeek">
        <span>←</span>
      </button>
      <button class="information-action__calendar" @click="resetWeek"><span>Today</span></button>
      <button class="information-action__next" @click="getNextWeek">
        <span>→</span>
      </button>
    </div>
  </section>
</template>

<script>
import { LOCALES } from "../constants/Locales";

export default {
  data() {
    return {
      currentWeek: 0,
    };
  },
  computed: {
    weekDates() {
      return this.getWeek(this.currentWeek);
    },
  },
  methods: {
    getWeek(weeksOffset = 0) {
      const WEEK_DAYS = 7;
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const daysSinceMonday = (currentDay + 6) % WEEK_DAYS;
      const targetMonday = new Date(currentDate);
      targetMonday.setDate(
        currentDate.getDate() - daysSinceMonday + weeksOffset * WEEK_DAYS
      );

      const weekDates = Array.from({ length: WEEK_DAYS }, (_, index) => {
        const date = new Date(targetMonday);
        date.setDate(targetMonday.getDate() + index);
        return date;
      });
      return weekDates;
    },
    getPreviousWeek() {
      this.currentWeek--;
      this.refetch();
    },
    getNextWeek() {
      this.currentWeek++;
      this.refetch();
    },
    resetWeek() {
      this.currentWeek = 0;
      this.refetch();
    },
    refetch() {
      this.$emit("refetch", this.currentWeek);
    },
    formatDate(date) {
      const i18nLocale = this.$i18n.locale;
      const currentLocale = LOCALES[i18nLocale];
      return date.toLocaleDateString(currentLocale, {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./WeekInformation.scss" />
