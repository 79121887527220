<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window">
      <div class="ui-modal__heading">
        <h3>{{ $t("create_debit_modal.adding_a_receipt") }}</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form class="ui-modal__form" @submit.prevent="createDebit">
        <div class="ui-form-group">
          <label class="ui-label">{{ $t("create_debit_modal.date") }}</label>
          <vue-date-picker
            locale="ru"
            v-model="debit.createdAt"
            :dark="$themeStore.isDarkTheme"
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">{{
            $t("create_debit_modal.title_of_receipt")
          }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('create_debit_modal.title_of_receipt')"
            v-model="debit.name"
            required
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">{{
            $t("create_debit_modal.receipt_amount")
          }}</label>
          <input
            type="number"
            class="ui-input"
            :placeholder="$t('create_debit_modal.receipt_amount')"
            v-model="debit.sum"
            min="0"
            step="0.01"
            required
          />
        </div>
        <div class="ui-form-group" v-if="multiselect.debitStatuses.length">
          <label class="ui-label">{{ $t("create_debit_modal.status") }}</label>
          <multi-select-wr
            v-model="multiselect.debitStatusObject"
            :placeholder="$t('create_debit_modal.select_status')"
            label="name"
            track-by="id"
            :options="multiselect.debitStatuses"
            @select="selectDebitStatus"
            @remove="removeDebitStatus"
            required
          />
        </div>
        <button
          type="submit"
          class="ui-button"
          v-text="$t('create_debit_modal.save')"
        />
      </form>
    </div>
  </div>
</template>

<script>
import MultiSelectWr from "@/global-components/MultiSelectWr.vue";

export default {
  components: { MultiSelectWr },
  props: ["eventId"],
  emits: ["closeModal", "success"],
  data() {
    return {
      multiselect: {
        debitStatuses: [],
        debitStatusObject: null,
      },
      debit: {
        eventId: this.$props.eventId,
        name: null,
        sum: null,
        debitStatusId: null,
        createdAt: Date.now(),
      },
      debitStatuses: [],
      isLoading: false,
    };
  },
  methods: {
    selectDebitStatus(status) {
      this.debit.debitStatusId = status.id;
    },
    removeDebitStatus() {
      this.debit.debitStatusId = null;
    },
    async getDebitStatuses() {
      try {
        const { data } = await this.$api.get("/v1/debit-status/list");
        this.debitStatuses = data;
      } catch (e) {
        this.$catch(
          e,
          this.$t("create_debit_modal.errors.error_receiving_data")
        );
      }
    },
    createDebit() {
      if (this.isLoading) return false;
      this.isLoading = true;

      // 2024-03-26 09:46:10
      const date = new Date(this.debit.createdAt)
      const createdAt = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

      const data = {
        ...this.debit,
        createdAt
      }

      this.$api
        .post(`/v1/debit/create`, data)
        .then(() => {
          this.$toast(
            "success",
            this.$t("create_debit_modal.statuses.receipt_added")
          );
          this.$emit("success");
        })
        .catch((e) => {
          this.$catch(
            e,
            this.$t("create_debit_modal.errors.error_adding_data")
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  async mounted() {
    await Promise.all([this.getDebitStatuses()]).then(() => {
      // Multiselect :: map statuses
      this.multiselect.debitStatuses = this.debitStatuses.map((status) => {
        return {
          id: status.id,
          name: status.name,
        };
      });
    });
  },
};
</script>
