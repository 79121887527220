<template>
  <div class="preloader">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        :fill="fillTheme"
      />
      <path
        d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
        :fill="fillTheme"
      />
    </svg>
    <slot />
  </div>
</template>

<script>
export default {
  name: "PreloaderComponent",
  data() {
    return {
      svgContent: "",
    };
  },
  async mounted() {
    this.svgContent = await this.fetchSvgContent();
  },
  methods: {
    async fetchSvgContent() {
      const response = await fetch(require("@/assets/icons/spinner.svg"));
      return await response.text();
    },
  },
  computed: {
    fillTheme() {
      return this.$themeStore.isDarkTheme ? "#fff" : "#000";
    },
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 48px;
    width: 48px;
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
