<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window">
      <div class="ui-modal__heading">
        <h3>{{ $t('edit_credit_type.editing_flow_type') }}</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form class="ui-modal__form" @submit.prevent="updateCreditType">
        <div class="ui-form-group">
          <label class="ui-label">{{ $t('edit_credit_type.expense_name') }}</label>
          <input
            type="text"
            class="ui-input"
            :placeholder="$t('edit_credit_type.flow_type_name')"
            v-model="creditType.name"
            required
          />
        </div>

        <button type="submit" class="ui-button" v-text="$t('edit_credit_type.save')" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['creditTypeId'],
  data() {
    return {
      creditType: {
        id: null,
        name: ''
      },
      isLoading: false
    }
  },
  methods: {
    updateCreditType() {
      if (this.isLoading) return false
      this.isLoading = true
      this
        .$api
        .post('/v1/credit-type/update', this.creditType)
        .then(() => {
          this.$toast('success', this.$t('edit_credit_type.statuses.flow_type_updated'))
          this.$emit('successModal')
        })
        .catch(e => this.$catch(e, this.$t('edit_credit_type.errors.failed_add_expense_type')))
        .finally(() => this.isLoading = false)
    },
    async getCreditType() {
      try {
        const { data } = await this.$api.get(`/credit-type/get/${this.$props.creditTypeId}`)

        this.creditType = data
      } catch (e) {
        this.$catch(e, this.$t('edit_credit_type.errors.failed_retrieve_flow_type_data'))
      }
    }
  },
  mounted() {
    Promise
      .all([
        this.getCreditType()
      ])
  }
}
</script>