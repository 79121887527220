<template>
  <button class="add" v-bind="$attrs">
    <span class="add__text">+ {{$t('tasks.actions.addNew')}}</span>
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style scoped lang="scss">
.add {
  background: inherit;
  color: var(--color);
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  border-radius: 10px;
  height: 40px;
  border: 1px dashed;
  cursor: pointer;
  font-weight: 600;
  &__icon {
    opacity: 0.75;
  }

  &:hover {
    border-style: solid;
  }

  &:active {
    scale: 0.95;
  }
}
</style>
