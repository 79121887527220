<template>
  <bar
    :id="id"
    :options="chartOptions"
    :data="chartData"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    id: {
      type: String,
      default: 'bar-chart'
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: [Number, String],
      default: 0
    },
    height: {
      type: [Number, String],
      default: 0
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  }
}
</script>
