<template>
    <div class="ui-modal">
        <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
        <div class="ui-modal__window">
            <div class="ui-modal__heading">
                <h3>{{ $t('create_user_modal.adding_user') }}</h3>
                <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
            </div>

            <form class="ui-modal__form" @submit.prevent="createUser">
                <div class="ui-form-group" v-if="userRoles.length">
                    <label class="ui-label">{{ $t('create_user_modal.role') }}</label>
                    <select
                        v-model="user.roleId"
                        class="ui-input"
                        required
                    >
                        <option
                            v-for="role in userRoles"
                            :key="role.id"
                            :value="role.id"
                            :text="role.name"
                        />
                    </select>
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">{{ $t('create_user_modal.name') }}</label>
                    <input
                        type="text"
                        class="ui-input"
                        :placeholder="$t('create_user_modal.name')"
                        v-model="user.name"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">{{ $t('create_user_modal.email') }}</label>
                    <input
                        type="email"
                        class="ui-input"
                        :placeholder="$t('create_user_modal.email')"
                        v-model="user.email"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">{{ $t('create_user_modal.phone_number') }}</label>
                    <phone-input
                      :placeholder="$t('create_user_modal.phone_number')"
                      :value="user.phone"
                      @input="inputPhone"
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">{{ $t('create_user_modal.password') }}</label>
                    <input
                        type="password"
                        class="ui-input"
                        :placeholder="$t('create_user_modal.password')"
                        v-model="user.password"
                        minlength="6"
                        required
                    />
                </div>
                <div class="ui-form-group" v-if="user.roleId === 3 || user.roleId === 4">
                    <label class="ui-label">{{ $t('create_user_modal.company_name') }}</label>
                    <input
                        type="text"
                        class="ui-input"
                        :placeholder="$t('create_user_modal.company_name')"
                        v-model="user.company"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">{{ $t('create_user_modal.address') }}</label>
                    <input
                        type="text"
                        class="ui-input"
                        :placeholder="$t('create_user_modal.address')"
                        v-model="user.address"
                        required
                    />
                </div>

                <button type="submit" class="ui-button" v-text="$t('create_user_modal.save')" />
            </form>
        </div>
    </div>
</template>

<script>
import PhoneInput from "@/global-components/PhoneInput.vue";

const DEFAULT_ROLE = 2

export default {
    components: { PhoneInput },
    data() {
        return {
            user: {
                roleId: DEFAULT_ROLE,
                email: null,
                password: null,
                name: null,
                phone: null,
                address: null,
                company: null,
                description: null,
                creatorId: this.$userStore.userId
            },
            userRoles: [],
            isLoading: false
        }
    },
    methods: {
        inputPhone(e) {
          this.user.phone = e.target.value
        },
        async getUserRoles() {
            try {
                const {data} = await this.$api.get('/v1/user-role/list')
                this.userRoles = data.filter(({ name }) => !["Superuser", "Компания"].includes(name))
            } catch(e) {
                this.$catch(e, this.$t('create_user_modal.errors.error_while_receiving_data'))
            }
        },
        createUser() {
            if (this.isLoading) return false
            this.isLoading = true

            this.$api
                .post(`/v1/user/create`, this.user)
                .then(() => {
                    this.$toast('success', this.$t('create_user_modal.statuses.user_added'))
                    this.$emit('success')
                })
                .catch(e => {
                    this.$catch(e, this.$t('create_user_modal.errors.error_adding_user'))
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    },
    async mounted() {
        await this.getUserRoles()
    }
}
</script>
