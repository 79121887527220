<template>
  <div
    :class="{
      'db-main-view__event': true,
      'db-main-view__event--active': $props.event.isOpen,
    }"
    @click.prevent="$emit('openEvent')"
  >
    <div class="db-main-view__event-heading">
      <span class="db-main-view__event-name" v-text="$props.event.name" />
      <span
        class="db-main-view__event-date"
        v-text="$convertDate($props.event.createdAt)"
      />
    </div>
    <div class="db-main-view__event-details">
      <div
        class="db-main-view__event-manager"
        v-if="$props.event.manager?.contact?.name"
        v-text="$props.event.manager.contact.name"
      />
      <div
        class="db-main-view__event-client"
        v-if="$props.event.client?.contact?.company"
        v-text="$props.event.client.contact.company"
      />
      <div
        class="db-main-view__event-debit"
        v-text="`${$t('single_event.receipts')}: ${$convertToCurrency($props.event.debitsSum)}`"
      />
      <div
        class="db-main-view__event-credit"
        v-text="`${$t('single_event.expenses')}: ${$convertToCurrency($props.event.creditsSum)}`"
      />
      <div
        class="db-main-view__event-balance"
        v-text="`${$t('single_event.bottom_line')}: ${$convertToCurrency($props.event.total)}`"
      />
      <div
        :class="{
          'db-main-view__event-status': true,
          red: $props.event.status.id === 1,
          green: $props.event.status.id === 2
        }"
        v-text="$t($props.event.status.name)"
      />
      <div
        v-show="$props.event.canEdit && this.$checkPermissions([1, 2])"
        class="db-main-view__event-buttons"
      >
        <div
          class="db-main-view__event-delete"
          @click.stop="deleteEvent($props.event.id)"
        />
        <div
          class="db-main-view__event-edit"
          @click.stop="openEditEventModal($props.event.id)"
        />
      </div>
    </div>
  </div>

  <EditEventModal
    @closeModal="closeEditEventModal"
    @success="successEditEventModal"
    :eventId="editEvent.id"
    v-if="editEvent.isOpen"
  />

  <!-- confirmation dialog -->
  <ConfirmationDialog ref="confirmationDialog" />
</template>

<script>
import EditEventModal from "../edit-event/EditEventModal.vue";
import ConfirmationDialog from '../../../components/confirmation/ConfirmationDialog.vue';

export default {
  props: ['event'],
  emits: ['updateEvent', 'openEvent'],
  components: { EditEventModal, ConfirmationDialog },
  data() {
    return {
      editEvent: {
        id: null,
        isOpen: false
      }
    }
  },
  methods: {
    openEditEventModal(id) {
      this.editEvent.id = id
      this.editEvent.isOpen = true
    },
    closeEditEventModal() {
      this.editEvent.isOpen = false
    },
    successEditEventModal() {
      this.$emit('updateEvent')
      this.closeEditEventModal()
    },
    async deleteEvent(id) {
      // confirmation dialog
      const ok = await this.$refs.confirmationDialog.show({
        title: this.$t('single_event.messages.confirm_deletion'),
        content: this.$t('single_event.messages.are_you_sure_want_delete_event')
      })

      if (!ok) return;

      try {
        await this.$api.delete(`/v1/events/delete/${id}`);
        this.$notificationsStore.statistics = true;
        this.$emit('updateEvent')
      } catch (e) {
        this.$toast("error", this.$t('single_event.errors.insufficient_rights_delete_object'));
      }
    },
  }
}
</script>