import { createRouter, createWebHistory } from "vue-router";

import AuthLayout from "@/layouts/auth/AuthLayout.vue";
import DashboardLayout from "@/layouts/dashboard/DashboardLayout.vue";

import LoginView from "@/pages/auth/LoginView.vue";
// import RegisterView from "@/pages/auth/RegisterView.vue";
// import NewPasswordView from "@/pages/password-recovery/NewPassword.vue";
// import PasswordRecoveryView from "@/pages/password-recovery/PasswordRecovery.vue";

import EventsList from "@/pages/dashboard/events/EventsList.vue";

import UserListView from "@/pages/dashboard/users/list/UserListView.vue";

import StatisticsView from "@/pages/dashboard/statistics/StatisticsView.vue";

import PersonalAccountView from "@/pages/dashboard/personal-account/PersonalAccountView.vue";

import SettingsView from "@/pages/dashboard/settings/SettingsView.vue";
import AdminStatisticsView from "@/pages/dashboard/admin-statistics/AdminStatisticsView.vue";

import CreditTypesView from "@/pages/dashboard/credit-types/CreditTypesView.vue";

import TasksManagerView from "@/pages/dashboard/tasks/TasksManagerView.vue";
import MobileView from "@/pages/mobile/MobileView.vue";
import { useUserStore } from "@/stores/user";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      redirect: {
        name: "account-layout",
      },
      children: [
        {
          path: "mobile-page",
          name: "mobile-page",
          component: MobileView,
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "account",
          name: "account-layout",
          component: AuthLayout,
          redirect: {
            name: "login",
          },
          children: [
            {
              path: "login",
              name: "login",
              component: LoginView,
              meta: {
                requiresAuth: false,
                title: "page_title.authorization",
              },
            },
            // {
            //   path: "register",
            //   name: "register",
            //   component: RegisterView,
            //   meta: {
            //     requiresAuth: false,
            //     title: "page_title.registration",
            //   },
            // },
            // {
            //   path: "password-recovery",
            //   name: "password-recovery",
            //   component: PasswordRecoveryView,
            //   meta: {
            //     requiresAuth: false,
            //     title: "page_title.password_recovery",
            //   },
            // },
            // {
            //   path: "new-password/:secret",
            //   name: "new-password",
            //   component: NewPasswordView,
            //   meta: {
            //     requiresAuth: false,
            //     title: "page_title.password_recovery",
            //   },
            // },
          ],
        },
        {
          path: "dashboard",
          name: "dashboard-layout",
          component: DashboardLayout,
          meta: {
            requiresAuth: true,
          },
          redirect: {
            name: "dashboard-main",
          },
          children: [
            {
              path: "credit-types",
              name: "credit-types",
              component: CreditTypesView,
              meta: {
                requiresAuth: true,
                title: "page_title.types_of_expenses",
              },
            },
            {
              path: "dashboard-main",
              name: "dashboard-main",
              component: EventsList,
              meta: {
                requiresAuth: true,
                title: "page_title.events",
              },
            },
            {
              path: "users",
              name: "users",
              component: UserListView,
              meta: {
                requiresAuth: true,
                title: "page_title.user_list",
              },
            },
            {
              path: "statistics",
              name: "statistics",
              component: StatisticsView,
              meta: {
                requiresAuth: true,
                title: "page_title.statistics",
              },
            },
            {
              path: "tasks",
              name: "tasks",
              component: TasksManagerView,
              meta: {
                requiresAuth: true,
                title: "page_title.task_manager",
              },
            },
            {
              path: "personal-account",
              name: "personal-account",
              component: PersonalAccountView,
              meta: {
                requiresAuth: true,
                title: "page_title.personal_account",
              },
            },
            {
              path: "settings",
              name: "settings",
              component: SettingsView,
              meta: {
                requiresAuth: true,
                title: "page_title.settings",
              },
            },
            {
              path: "admin-statistics",
              name: "admin-statistics",
              component: AdminStatisticsView,
              meta: {
                requiresAuth: true,
                title: "page_title.admin_statistics",
              },
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach((to) => {
  const userStore = useUserStore();
  if (
    (to.meta.requiresAuth && !userStore.token) ||
    (to.meta.requiresAuth && userStore.token === "undefined")
  ) {
    return {
      path: "/",
    };
  }
});

export default router;
